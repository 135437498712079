<template>
  <transition name="slide-header">
    <div v-if="showHeader || notificationStore.totalUnseen > 0">
      <div
        class="select-none cursor-pointer relative px-2 sm:px-4 md:px-6 py-2 sm:py-3 md:py-4 flex items-center bg-neutral-50 dark:bg-zinc-950 shadow-sm"
      >
        <ion-icon
          v-if="showBackButton"
          :icon="chevronBackOutline"
          class="text-3xl sm:text-4xl md:text-5xl text-black dark:text-white -pr-2"
          @click="goBack"
        />
        <TenantLogo
          class="md:hidden"
          :home="route.path === '/'"
          :small="true"
          :mainHeader="true"
          :loading="loading"
          @logo-click="logoClick"
          :searchClicked="quickSearch"
        />
        <QuickSearch
          v-if="(quickSearch || search) && globalStore.isParler"
          :searchPage="search"
          @close="closeQuickSearch"
          @new-search="emitNewSearch"
          @clear-search="emit('clear-search')"
          @quick-search="emitQuickSearch"
          :currentSearchType="currentSearchType"
          :currentSearchInput="searchInput"
        />
        <div
          v-if="!public"
          class="ml-auto flex items-center space-x-2 md:space-x-4 sm:space-x-6"
        >
          <SearchIcon
            v-if="globalStore.isParler && !quickSearch"
            @click="toggleQuickSearch"
            :active="quickSearch"
            :currentSearchType="currentSearchType"
            :height="32"
            :width="32"
          />
          <div
            v-show="checkNotifications"
            class="relative flex items-center justify-center"
          >
            <NotificationIcon
              v-if="globalStore.isParler"
              :active="notificationStore.totalUnseen > 0"
              @click="navigateToNotifications()"
            />
            <ion-icon
              v-else
              :icon="notificationsIcon"
              :class="[
                notificationStore.totalUnseen > 0
                  ? 'text-red-500'
                  : 'text-black dark:text-white',
                animateBell ? 'animate-ring' : '',
              ]"
              class="text-3xl sm:text-4xl md:text-5xl"
              @click="navigateToNotifications()"
            />
            <span
              @click="navigateToNotifications()"
              class="bg-black dark:bg-white text-white dark:text-black absolute rounded-md px-1 ml-4 -mt-6 h-4 pb-6 text-sm overflow-hidden"
              v-if="unseenNotifications > 0 || unseenNotifications === '99+'"
            >
              <FormatCount
                class="mb-1"
                v-if="notificationStore.totalUnseen < 99"
                :count="notificationStore.totalUnseen"
              />
              <span else>
                {{ unseenNotifications }}
              </span>
            </span>
          </div>
          <ion-icon
            v-if="!search && !globalStore.isParler"
            :icon="searchOutline"
            class="text-3xl sm:text-4xl md:text-5xl text-black dark:text-white"
            @click="navigateToSearch()"
          />
        </div>
      </div>
      <FeedHeader
        v-if="showFeedHeader && showHeader"
        @scroll-top="emit('scroll-top')"
        @feedTypeSelected="handleFeedTypeSelected"
        :showCreatePost="showCreatePost"
      />
    </div>
  </transition>
</template>
<script setup>
import { IonIcon } from "@ionic/vue";
import {
  notificationsOutline,
  notifications,
  searchOutline,
  chevronBackOutline,
} from "ionicons/icons";
import { computed, ref, onMounted, watch, onBeforeUnmount } from "vue";
import SearchIcon from "@/components/tenantcomponents/parler/icons/SearchIcon.vue";
import { useNotificationStore } from "@/stores/notification";
import { useGlobalStore } from "@/stores/global";
import { useRoute, useRouter } from "vue-router";
import TenantLogo from "./tenantcomponents/TenantLogo.vue";
import TittleIcon from "@/components/TittleIcon.vue";
import FormatCount from "@/components/FormatCount.vue";
import FeedHeader from "./tenantcomponents/parler/FeedHeader.vue";
import QuickSearch from "@/components/tenantcomponents/parler/QuickSearch.vue";
import NotificationIcon from "./tenantcomponents/parler/icons/NotificationIcon.vue";

const props = defineProps({
  currentPage: {
    type: String,
    required: false,
  },
  scrollToTop: {
    type: Function,
    required: false,
    default: null,
  },
  checkNotifications: {
    type: Boolean,
    required: false,
    default: true,
  },
  search: {
    type: Boolean,
    required: false,
    default: false,
  },
  showHeader: {
    type: Boolean,
    required: false,
    default: true,
  },
  currentFeed: {
    type: String,
    required: false,
  },
  quickSearchDefault: {
    type: Boolean,
    required: false,
    default: false,
  },
  searchInput: {
    type: String,
    required: false,
  },
  currentSearchType: {
    type: String,
    required: false,
    default: "",
  },
  public: {
    type: Boolean,
    required: false,
    default: false,
  },
  showCreatePost: {
    type: Boolean,
    required: false,
    default: false,
  },
});

watch(
  () => props.searchInput,
  (value) => {}
);

const route = useRoute();
const router = useRouter();
const darkMode = computed(() => globalStore.settings.dark_mode);
const emit = defineEmits([
  "refresh",
  "logoClicked",
  "refreshFeed",
  "updateFeedType",
  "clear-search",
  "new-search",
  "quick-search",
  "logoClicked",
  "scroll-top",
]);
const notificationStore = useNotificationStore();
const globalStore = useGlobalStore();
const loading = ref(false);
const previousCount = ref(0);
const animateBell = ref(false);
const bellTimeout = ref(null);
const logoTimeout = ref(null);
const showBackButton = ref(false);
const quickSearch = ref(props.quickSearchDefault);

const toggleQuickSearch = () => {
  quickSearch.value = !quickSearch.value;
};

const closeQuickSearch = () => {
  quickSearch.value = false;
};

const emitQuickSearch = (query, type) => {
  emit("quick-search", query, type);
};

function goBack() {
  router.back();
}

function handleFeedTypeSelected(feedType) {
  loading.value = true;

  if (route.path !== "/feed") {
    // Navigate to the home view ("/feed") with the specified feedType
    router.push({ path: "/feed", query: { feedType } });
  } else {
    emit("updateFeedType", feedType);
  }

  logoTimeout.value = setTimeout(() => {
    loading.value = false;
  }, 1000);
}

const tenant = ref(globalStore.currentTenant);

const showFeedHeader = computed(() => {
  return globalStore.isParler && route.path === "/feed";
});

watch(
  () => notificationStore.totalUnseen,
  (currentCount, previousValue) => {
    if (currentCount > previousCount.value) {
      animateBell.value = true;

      // Reset the animation
      bellTimeout.value = setTimeout(() => {
        animateBell.value = false;
      }, 500); // Ensure this duration matches your CSS animation duration
    }

    // Update the previous count
    previousCount.value = currentCount;
  }
);

const checkBackButton = () => {
  if (
    route.name !== "home" &&
    route.name !== "profile" &&
    route.name !== "Trending" &&
    route.name !== "Group" &&
    route.name !== "GroupMembers" &&
    route.name !== "Groups" &&
    route.name !== "policy"
  ) {
    showBackButton.value = window.history.length > 1;
  } else {
    showBackButton.value = false;
  }
};

onMounted(() => {
  countUnseenNotifications();

  checkBackButton();
});

const unseenNotifications = computed(() => {
  return notificationStore.totalUnseen > 99
    ? "99+"
    : notificationStore.totalUnseen;
});

const countUnseenNotifications = async () => {
  try {
    await notificationStore.checkNotifications();
  } catch (error) {
    console.error("Failed to fetch notification counts:", error);
  }
};

const notificationsIcon = computed(() => {
  const icon =
    notificationStore.totalUnseen > 0 ? notifications : notificationsOutline;

  return icon;
});

const navigateToNotifications = () => {
  router.push(`/notifications`);
};

const navigateToSearch = () => {
  router.push(`/search`);
};

// compute title based on currentPage
const currentTitle = computed(() => {
  let page = props.currentPage;

  if (typeof page !== "string" || page.length === 0) {
    // If currentPage isn't provided, use the path from the URL
    const urlPath = window.location.pathname;
    // Extracting the part after the '/' and converting the first character to uppercase
    page = urlPath.split("/")[1] || "";
  }

  switch (page) {
    case "settings":
      return "Settings";
    case "notifications":
      return "Notifications";

    default:
      return page;
  }
});

const setFeedType = () => {
  if (globalStore.settings.default_feed) {
    globalStore.currentFeed = globalStore.settings.default_feed;
  } else {
    globalStore.currentFeed = "following";
  }
};

function logoClick() {
  globalStore.currentFeed = globalStore.settings.default_feed;
  // if route.path is not '/' then redirect to '/' else emit event
  if (route.path !== "/feed") {
    router.push("/feed");
    emit("scroll-top");
    return;
  }
  loading.value = true;
  countUnseenNotifications();
  emit("scroll-top");

  setFeedType();

  logoTimeout.value = setTimeout(() => {
    loading.value = false;
  }, 1000);
}

const refreshTimeout = ref(null);

function RefreshPage() {
  if (route.path === "/feed" || props.currentPage === "Profile") {
    loading.value = true;
    emit("refreshFeed");

    refreshTimeout.value = setTimeout(() => {
      loading.value = false;
    }, 1000);
    return;
  }

  // if route.path is not '/' then redirect to '/' else emit event
  emit("refresh");
}

function emitNewSearch(query, type) {
  emit("new-search", query, type);
}

onBeforeUnmount(() => {
  if (bellTimeout.value) {
    clearTimeout(bellTimeout.value);
  }
  if (logoTimeout.value) {
    clearTimeout(logoTimeout.value);
  }
  if (refreshTimeout.value) {
    clearTimeout(refreshTimeout.value);
  }
});
</script>

<style scoped>
@keyframes ringBell {
  0%,
  100% {
    transform: rotate(-15deg);
  }

  50% {
    transform: rotate(15deg);
  }
}

ion-icon {
  --ionicon-stroke-width: 48px;
}

.notificationAlert {
  position: absolute;
  top: 0;
  right: 0;
  background: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  z-index: 1;
}

/* Add transition styles for the header */
.slide-header-enter-active,
.slide-header-leave-active {
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.slide-header-enter-from,
.slide-header-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}

.slide-header-enter-to,
.slide-header-leave-from {
  transform: translateY(0);
  opacity: 1;
}
</style>
