<template>
  <div
    v-if="postObject"
    ref="commentContainer"
    class="shadow-none rounded-none ion-no-margin comment-element"
    :class="{ 'ml-2 w-[95%]': postObject?.postType === 'REPLY' }"
    :data-id="`comment-${postObject?.id}`"
    :id="`comment-${postObject?.id}`"
    :detailshow="detailsShow"
  >
    <div class="w-full pl-0 pt-2 pb-1">
      <PostHeader
        :parentId="postObject?.parentUlid"
        :depth="depth"
        :isCommentDetails="true"
        :id="postObject?.id"
        :userId="postObject.user?.userId"
        :createdAt="postObject.createdAt"
        :repost-user-id="postObject.isRepost ? postObject.user?.userId : null"
        :repost-id="postObject.isRepost ? postObject.id : null"
        :views="postObject.postEngagement ? postObject.postEngagement.views : 0"
      />
    </div>
    <div class="-mt-2 pb-1 pl-1">
      <div
        :class="
          postObject.postEngagement?.commentCount > 0
            ? 'ml-[13px] mt-2 pb-0 border-gray-300 dark:border-gray-100 border-l border-t-0 border-r-0 border-b-0'
            : ''
        "
      >
        <div class="ml-2">
          <PostBody :parentItem="postObject" :isCommentDetails="true" />
        </div>
        <UserEngagement
          :isCommentDetails="true"
          :postId="postObject.id"
          :detailsShow="detailsShow"
          :parentId="postObject.id"
          :isNestedFeed="isNestedFeed"
          :parentFilter="parentFilter"
          :parentSort="parentSort"
          :depth="depth"
          @comment-ids="addCommentIds"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import UserEngagement from "@/components/engadgement/UserEngagement.vue";
import PostHeader from "@/components/posts/PostHeader.vue";
import PostBody from "@/components/posts/PostBody.vue";
import PostSkeleton from "@/components/posts/PostSkeleton.vue";
import { usePostStore } from "@/stores/post";
import { computed, ref, watch, onBeforeUnmount, onMounted } from "vue";

const postStore = usePostStore();
const props = defineProps({
  parentItemId: String,
  index: Number,
  commentItemClass: String,
  detailsShow: {
    type: Boolean,
    default: false,
  },
  feedShow: {
    type: Boolean,
    default: true,
  },
  isNestedFeed: {
    type: Boolean,
    default: true,
  },
  parentFilter: {
    type: Boolean,
    default: null,
  },
  parentSort: {
    type: String,
    defualt: null,
  },
  isFollowing: {
    type: Boolean,
    default: false,
  },
  depth: {
    type: Number,
    default: 0,
  },
});

const commentContainer = ref(null);

onMounted(() => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        emit("comment-id", props.parentItemId);
        observer.disconnect(); // Stop observing after emitting the comment-id
      }
    });
  });

  if (commentContainer.value) {
    observer.observe(commentContainer.value);
  }
});

const postObject = computed(() => {
  return postStore.postsCache[props.parentItemId];
});

const emit = defineEmits(["comment-ids", "comment-deleted", "comment-id"]);

const commentIds = ref([]);

const addCommentIds = (ids) => {
  commentIds.value = [...commentIds.value, ...ids];
  emit("comment-ids", commentIds.value);
};

//Watch to see if the post is deleted
watch(
  () => postObject.value,
  (newVal, oldVal) => {
    if (newVal && newVal.isDeleted) {
      emit("comment-deleted", props.parentItemId);
    }
  }
);

onBeforeUnmount(() => {
  commentIds.value = [];
});
</script>
