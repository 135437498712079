<template>
  <div
    v-if="!isIos || userLoaded"
    ref="mediaContainer"
    :key="`media-${parentItem.id}`"
  >
    <div
      v-if="parentItem.images.length > 0 && parentItem?.isProcessing"
      class="animate-pulse flex space-y-2 items-center text-center justify-center"
    >
      <p class="font-bold">Your media is processing</p>
      <ion-spinner name="dots" />
    </div>

    <PostEmbeds
      v-if="parentItem && parentItem.embedUrl && parentItem.images.length === 0"
      :post-data="parentItem"
      :postInView="postInView"
      :mediaUrl="parentItem?.openGraph?.url || ''"
    />

    <!-- OG data with no images should be handled in PostBody, not here -->

    <div class="p-0 m-0" v-if="parentItem && parentItem.images">
      <div
        :class="{
          relative: mediaOnly,
        }"
      >
        <div
          v-if="mediaOnly"
          class="absolute top-0 right-0 bg-black bg-opacity-05 rounded-md z-50"
        >
          <ion-icon
            :icon="openSharp"
            class="text-4xl text-white shadow-xl pl-1 pb-1 cursor-pointer select-none"
            @click="emitNavigateToContent(parentItem.id)"
          />
        </div>

        <ImageCarousel
          v-if="
            parentItem.images.length > 1 ||
            (parentItem.images.length > 0 &&
              (parentItem.embedUrl ||
                (parentItem.openGraph?.url && !parentItem.embedUrl)))
          "
          :images="parentItem.images"
          :mediaOnly="mediaOnly"
          :mediaTypes="parentItem.mediaTypes || []"
          :postData="parentItem"
          :id="parentItem.id"
          @media-height="setCarouselHeight"
          :imageWidth="imageWidth"
          :postInView="postInView"
        >
        </ImageCarousel>

        <div
          v-else-if="parentItem.images.length === 1"
          class="flex justify-center items-center overflow-y-hidden w-full relative"
        >
          <!-- Image Loading Placeholder -->
          <div
            :style="{ height: imgHeight + 'px' }"
            v-if="
              isImage(parentItem.images[0]?.url) &&
              isImageLoading &&
              !mediaLoadError
            "
            class="absolute animate-pulse flex flex-col items-center justify-center md:mx-auto w-full text-gray-800 dark:text-white"
          >
            <div class="flex flex-col items-center justify-center w-full">
              <ion-icon :icon="imageOutline" class="text-6xl animate-pulse" />
            </div>
            <!-- Loading Text -->
            <div class="flex items-center overflow-hidden">
              <p class="mr-2 animate-pulse">Loading</p>
              <ion-spinner
                class="text-gray-800 dark:text-white align-bottom"
                name="dots"
              />
            </div>
          </div>
          <div
            class="relative w-full cursor-pointer"
            v-if="
              isImage(parentItem.images[0]?.url) ||
              (parentItem?.mediaTypes?.[0] === 'image' && !mediaLoadError)
            "
            @click.stop.prevent="emitNavigateToContent(parentItem.id)"
            :class="{ 'rounded-lg': true }"
          >
            <!-- Image Element -->
            <div class="relative w-full flex justify-center items-center">
              <img
                :src="
                  imageWidth
                    ? parentItem.images[0]?.url + `?width=${imageWidth}`
                    : parentItem.images[0]?.url
                "
                class="select-none max-h-[75vh] rounded-lg"
                ref="imgRef"
                @load="isImageLoading = false"
                @error="handleImageError"
              />
              <div
                class="absolute inset-0 m-auto cursor-pointer"
                :style="{
                  height: '100%',
                  width: '40%',
                  left: '30%',
                  transform: 'translateX(-50%)',
                }"
                @click.stop.prevent="showImageInModal()"
              ></div>
            </div>

            <!-- Remove the redundant OG display section -->
          </div>
          <!-- Video Player Placeholder -->
          <div
            class="relative overflow-hidden w-full flex rounded-lg justify-center select-none"
            v-else-if="
              isVideo(parentItem.images[0]?.url) ||
              (parentItem?.mediaTypes?.[0] === 'video' && !mediaLoadError)
            "
          >
            <VideoPlayer
              :videoUrl="parentItem.images[0].url"
              :thumbnailUrl="
                parentItem.images[0].thumbnail?.url
                  ? parentItem.images[0].thumbnail.url
                  : ''
              "
              :mimeType="parentItem.images[0].mimeType"
              :postId="parentItem?.id"
              :height="parentItem.images[0].heightPx"
              :width="parentItem.images[0].widthPx"
              :isProcessing="parentItem?.isProcessing"
              :last-watched="parentItem.images[0].lastWatched || 0"
            />
          </div>

          <!-- Error Display -->
          <div
            v-else-if="mediaLoadError"
            class="flex flex-col text-white items-center justify-center error-message rounded-lg w-full"
            :style="{ height: imgHeight + 'px' }"
          >
            <ion-icon :icon="imageOutline" class="text-6xl" />
            <p class="mt-4">Media not available</p>
          </div>
        </div>
        <ImageModal
          v-if="isModalVisible && parentItem.images.length === 1"
          :isModalVisible="isModalVisible"
          :modalContentUrls="parentItem.images[0].url"
          @closeModal="closeModal"
        />
      </div>
    </div>
  </div>

  <div
    v-else
    class="animate-pulse flex flex-col items-center justify-center md:mx-auto w-full text-gray-800 dark:text-white h-20"
  >
    <div class="flex flex-col items-center justify-center w-full">
      <ion-icon :icon="imageOutline" class="text-6xl animate-pulse" />
    </div>
    <!-- Loading Text -->
    <div class="flex items-center overflow-hidden">
      <p class="mr-2 animate-pulse">Loading</p>
      <ion-spinner
        class="text-gray-800 dark:text-white align-bottom"
        name="dots"
      />
    </div>
  </div>
</template>
<script setup>
// @ts-check

import { useAuthStore } from "@/stores/auth";
import { useUserStore } from "@/stores/users";
import { usePostStore } from "@/stores/post";
import ImageCarousel from "./ImageCarousel.vue";
import PostEmbeds from "../media/PostEmbeds.vue";
import ImageModal from "../media/ImageModal.vue";
import VideoPlayer from "../media/VideoPlayer.vue";
import OgDisplay from "../media/OgDisplay.vue";
import { IonIcon, IonImg, IonSpinner } from "@ionic/vue";
import { openSharp, imageOutline, images } from "ionicons/icons";
import { ref, onMounted, onBeforeUnmount, computed } from "vue";

const props = defineProps({
  parentItem: {
    type: Object,
    required: false,
  },
  detailsShow: {
    type: Boolean,
    required: false,
    default: false,
  },
  mainPost: {
    type: Boolean,
    required: false,
    default: false,
  },
  mediaOnly: {
    type: Boolean,
    required: false,
    default: false,
  },
  longBody: {
    type: Boolean,
    required: false,
    default: false,
  },
  computedHeight: {
    required: false,
    default: "auto",
  },
  postInView: {
    type: Boolean,
    required: false,
    default: false,
  },
  containerWidth: {
    type: Number,
    required: false,
    default: 0,
  },
  ogLink: {
    type: String,
    required: false,
    default: "",
  },
  ogDesc: {
    type: String,
    required: false,
    default: "",
  },
});

//trigger an update

const emit = defineEmits(["navigate-to-content", "media-height"]);
const authStore = useAuthStore();
const userStore = useUserStore();
const postStore = usePostStore();
const bodyRef = ref(null);
const showMore = ref(false);
const imgHeight = ref(0);
const imgWidth = ref(0);
const isImageLoading = ref(true);
const mediaLoadError = ref(false);
const resizeObserver = ref(null);
const mediaContainer = ref(null);

const predefinedSizes = [500, 1024, 1920]; // Mobile, tablet, desktop widths

let processingInterval = null;

onMounted(() => {
  if (props.parentItem && mediaContainer.value) {
    getImageHeight(props.parentItem?.images[0]);
  }

  resizeObserver.value = new ResizeObserver(() => {
    if (bodyRef.value?.scrollHeight > bodyRef.value?.clientHeight) {
      showMore.value = true;
    }
  });

  if (bodyRef.value) {
    resizeObserver.value.observe(bodyRef.value);
  }

  if (props.parentItem.isProcessing) {
    fetchPosts();
    processingInterval = setInterval(() => {
      fetchPosts();
    }, 15000); // Fetch posts every 15 seconds
  }
});

function fetchPosts() {
  postStore.fetchPostsByIds([props.parentItem.id], true).then(() => {
    if (!props.parentItem.isProcessing) {
      if (processingInterval) {
        clearInterval(processingInterval);
        processingInterval = null;
      }
    }
  });
}

// Remember to disconnect the observer when the component unmounts
onBeforeUnmount(() => {
  if (resizeObserver.value) {
    resizeObserver.value.disconnect();
  }
  if (processingInterval) {
    clearInterval(processingInterval);
    processingInterval = null;
  }
});

// Computed image width for src
const imageWidth = computed(() => {
  if (props.containerWidth === 0) {
    return 1024;
  }

  // Find the optimal size based on the container width
  const optimalSize = predefinedSizes.find(
    (size) => size >= props.containerWidth
  );

  // Return the largest predefined size if containerWidth exceeds all predefined sizes
  return optimalSize || predefinedSizes[predefinedSizes.length - 1];
});

const userLoaded = computed(() => {
  return userStore.users[props.parentItem?.user.userId];
});

const isIos = computed(() => {
  // @ts-ignore: Access to non-standard browser properties
  const isPWA = Boolean(window.webkit && window.webkit.messageHandlers);

  const isSafari =
    /iPad|iPhone|iPod/.test(navigator.userAgent) &&
    // @ts-ignore: Access to non-standard browser properties
    !window.MSStream;

  return isPWA || isSafari;
});

const setCarouselHeight = (height) => {
  imgHeight.value = height;
};

const emitNavigateToContent = (postId) => {
  emit("navigate-to-content", postId);
};

function handleImageError() {
  mediaLoadError.value = true;
}

function isImage(url) {
  return /\.(jpeg|jpg|JPG|gif|png|webp|svg|tiff|tif|bmp|ico|heif|heic|avif)$/.test(
    url
  );
}

async function isVideo(url) {
  // Check file extension first
  if (/\.(mp4|ogg|webm)$/i.test(url)) {
    return true; // If the URL doesn't end with a video file extension, return false immediately
  }
  if (props.parentItem?.images.mimeType === "video/mp4") {
    return true;
  }

  // If the extension is a video type, check the MIME type via a HEAD request
  try {
    const response = await fetch(url, { method: "HEAD" });
    const contentType = response.headers.get("Content-Type");
    return contentType && contentType.startsWith("video/"); // Ensure the MIME type starts with 'video/'
  } catch (error) {
    console.error("Error checking file type:", error);
    return false; // Return false if there's an error fetching the file
  }
}

// Modified openLink function to use openGraph URL
function openLink() {
  if (props.parentItem?.openGraph?.url) {
    window.open(props.parentItem.openGraph.url, "_blank");
  } else if (props.ogLink) {
    window.open(props.ogLink, "_blank");
  }
}

// Modal state
const isModalVisible = ref(false);
const modalImage = ref("");

async function getImageHeight(img) {
  if (!img) return;

  const { widthPx: originalWidth, heightPx: originalHeight } = img;
  const maxWidth = props.containerWidth;

  if (window.innerWidth < 768) {
    const maxHeight = window.innerHeight * 0.5;

    const widthRatio = maxWidth / originalWidth;
    const scaledHeight = originalHeight * widthRatio;

    imgHeight.value = scaledHeight > maxHeight ? maxHeight : scaledHeight;
    imgWidth.value =
      scaledHeight > maxHeight
        ? originalWidth * (maxHeight / originalHeight)
        : maxWidth;
  } else {
    imgHeight.value = originalHeight;
    imgWidth.value = originalWidth;
  }
}

async function showImageInModal() {
  isModalVisible.value = true;
}

function closeModal() {
  isModalVisible.value = false;
  modalImage.value = "";
}
</script>

<style scoped>
.tag-link {
  color: var(--tertiary-color);
  /* Additional styling as needed */
}

.max-w-full {
  width: 100%;
  /* Full width */
  height: auto;
  /* Auto height to maintain aspect ratio */
  object-fit: contain;
  /* Contain ensures the entire media is visible without being cut off */
}

.error-message {
  background: linear-gradient(
    to right,
    var(--primary-color, --secondary-color),
    var(--secondary-color, --primary-color)
  );
  opacity: 0.7;
}

ion-img::part(image) {
  border-radius: 0.5rem;
  max-height: 50vh;
}
</style>
