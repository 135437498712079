import { createApp } from "vue";
import { createPinia } from "pinia";
import { IonicVue } from "@ionic/vue";
import piniaPluginPersistedState from "pinia-plugin-persistedstate";
import App from "./App.vue";
import router from "./router";
import "./assets/main.scss";
import Vapor from "laravel-vapor";
import * as Sentry from "@sentry/vue";
import VueGtag from "vue-gtag";
import {
  initAuthInterceptors,
  validateAccessToken,
  refreshAccessToken,
} from "./axiosAuth";
import "./assets/main.scss";

// App version for cache validation
const APP_VERSION = import.meta.env.VITE_APP_VERSION || "1.0";

// Store the current version in localStorage
const storedVersion = localStorage.getItem("app_version");
if (storedVersion !== APP_VERSION) {
  // Version has changed - handle potential auth issues
  console.log(
    `App version changed from ${storedVersion || "unknown"} to ${APP_VERSION}`
  );
  localStorage.setItem("app_version", APP_VERSION);

  // Don't clear auth data yet - we'll validate it first
}

// import Pusher from 'pusher-js'

// Enable pusher logging - if in dev mode
// if (import.meta.env.MODE === 'development') {
//   Pusher.logToConsole = true;
// }

Vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL);
window.Vapor = Vapor;

const pinia = createPinia();
pinia.use(piniaPluginPersistedState);

const app = createApp(App);
app.mixin({
  methods: {
    asset: Vapor.asset,
  },
});

// Sentry.init({
//   app,
//   dsn: "https://a7856108d1e201a1ade2b326ebd1ef86@o4506616968773632.ingest.sentry.io/4506769909350400",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration({
//       maskAllText: false,
//       blockAllMedia: false,
//     }),
//   ],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,

//   // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/app.par.pw/],

//   // Capture Replay for 10% of all sessions,
//   // plus for 100% of sessions with an error
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

app.use(IonicVue).use(pinia).use(router);

// Initialize Google Analytics with VueGtag
if (import.meta.env.VITE_GA_MEASUREMENT_ID) {
  app.use(
    VueGtag,
    {
      config: {
        id: import.meta.env.VITE_GA_MEASUREMENT_ID,
        params: {
          anonymize_ip: true,
        },
      },
      pageTrackerScreenviewEnabled: true,
    },
    router
  );
}

// Handle service worker messages for version changes
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.addEventListener("message", async (event) => {
    if (event.data && event.data.action === "newVersionAvailable") {
      console.log("New app version detected via service worker");

      // If we have auth data, validate the token before proceeding
      const authData = JSON.parse(localStorage.getItem("auth") || "{}");
      if (authData && authData.accessToken) {
        try {
          // Try to validate and refresh the token if needed
          const isValid = await validateAccessToken(authData.accessToken);
          if (!isValid) {
            console.log("Token invalid after app update, attempting refresh");
            await refreshAccessToken();
          } else {
            console.log("Token still valid after app update");
          }
        } catch (error) {
          console.error("Error validating token after app update:", error);
          // Don't clear auth data here - let the auth interceptors handle this
        }
      }
    }
  });
}

// Initialize auth interceptors after pinia is available
router.isReady().then(() => {
  // Initialize auth interceptors to ensure token refresh works properly
  initAuthInterceptors();

  // Mount the app
  app.mount("#app");
});
