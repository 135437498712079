import axios from "axios";
import { useAuthStore } from "./stores/auth";
import { useGlobalStore } from "./stores/global";
import {
  sendToAnalytics,
  sendErrorToAnalytics,
} from "@/helpers/analyticsHelper";
import router from "@/router";

// Initialize variables
let failedQueue = [];
let countOfErrors = 0;

// Helper function to process the failed request queue
const processQueue = (error, token = null) => {
  // Create a local copy of the queue
  const currentQueue = [...failedQueue];
  // Clear the queue immediately to avoid race conditions
  failedQueue = [];

  // Process each queued request
  currentQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
};

// Helper function to handle spinner display
const startStopSpinner = (display = "none") => {
  const spinner = document.getElementById("spinner");
  if (spinner) spinner.style.display = display;
};

// Axios instance for authenticated API calls
const apiBackendAuthAxios = axios.create({
  baseURL: import.meta.env.VITE_API_HOST + "/v3",
});

// Request interceptor to append access token
apiBackendAuthAxios.interceptors.request.use(
  (config) => {
    startStopSpinner("block");
    const authStore = useAuthStore();
    const globalStore = useGlobalStore();

    // Check if we have an access token
    if (!authStore.accessToken) {
      console.error(`No access token available for request to ${config.url}`);
      // If a token refresh is in progress, let the request proceed
      // Otherwise log out the user
      if (!globalStore.isUpdatingAccessToken) {
        console.error(
          "No access token and no refresh in progress - signing out"
        );
        // Use setTimeout to avoid blocking the current call stack
        setTimeout(() => {
          authStore.setUnauthenticated(router);
        }, 0);
      }
    } else {
      // Add token to request header
      config.headers.Authorization = `Bearer ${authStore.accessToken}`;
    }

    return config;
  },
  (error) => {
    console.error("Error in request interceptor:", error.message);
    startStopSpinner();
    return Promise.reject(error);
  }
);

// Response interceptor for handling responses
apiBackendAuthAxios.interceptors.response.use(
  (response) => {
    handleSuccessResponse(response);
    return response;
  },
  (error) => handleErrorResponse(error)
);

// Axios instance for general API calls
const apiBackendAxios = axios.create({
  baseURL: import.meta.env.VITE_API_HOST,
});

// Request interceptor for general API calls
apiBackendAxios.interceptors.request.use(
  (config) => {
    startStopSpinner("block");
    return config;
  },
  (error) => {
    startStopSpinner();
    return Promise.reject(error);
  }
);

// Response interceptor for general API calls
apiBackendAxios.interceptors.response.use(
  (response) => {
    startStopSpinner();
    return response;
  },
  (error) => {
    startStopSpinner();
    return Promise.reject(error);
  }
);

// Function to handle successful API responses
const handleSuccessResponse = (response) => {
  startStopSpinner();
  const globalStore = useGlobalStore();
  countOfErrors = 0;

  if (globalStore.isBackendDown) {
    globalStore.setBackendDown(false);
  }

  sendToAnalytics({
    method: response.config.method,
    url: response.config.url,
  });
};

// Function to handle API response errors
const handleErrorResponse = async (error) => {
  startStopSpinner();
  const globalStore = useGlobalStore();

  if (isUnauthorizedError(error)) {
    return handleUnauthorizedError(error);
  }

  if (isServerError(error)) {
    countOfErrors++;
    if (countOfErrors > 5 && !globalStore.isBackendDown) {
      globalStore.setBackendDown(true);
    }
  }

  return Promise.reject(error);
};

// Check if the error is a 401 Unauthorized error
const isUnauthorizedError = (error) =>
  error.response && error.response.status === 401;

// Handle 401 Unauthorized errors by validating the access token and refreshing if needed
const handleUnauthorizedError = async (error) => {
  const originalConfig = error.config;
  const globalStore = useGlobalStore();
  const authStore = useAuthStore();

  // If we don't have a token at all, just log out
  if (!authStore.accessToken) {
    console.error("No access token available - signing out");
    authStore.setUnauthenticated(router);
    return Promise.reject(error);
  }

  try {
    // Prevent multiple retries of the same request
    if (originalConfig._retry) {
      console.error(
        `Request to ${originalConfig.url} already retried once, not retrying again.`
      );
      return Promise.reject(error);
    }

    // Mark the request as retried
    originalConfig._retry = true;

    // If we're already refreshing a token, queue this request
    if (globalStore.isUpdatingAccessToken) {
      return new Promise((resolve, reject) => {
        failedQueue.push({
          resolve: (token) => {
            try {
              // Update the token directly in the original config
              originalConfig.headers.Authorization = `Bearer ${token}`;

              // Use the original axios instance with original config to preserve transformers
              apiBackendAuthAxios(originalConfig)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
            } catch (err) {
              reject(err);
            }
          },
          reject: (err) => {
            reject(err);
          },
        });
      });
    }

    // Set updating flag to prevent other requests from refreshing token
    globalStore.setUpdatingAccessToken(true);

    // Set a timeout to ensure the flag gets reset even if refreshAccessToken never resolves
    const tokenRefreshTimeout = setTimeout(() => {
      console.error("Token refresh guard timeout triggered after 30 seconds");
      globalStore.setUpdatingAccessToken(false);
    }, 30000);

    try {
      // Skip token validation and go straight to refresh
      const newToken = await refreshAccessToken();

      // Clear the guard timeout
      clearTimeout(tokenRefreshTimeout);
      // Reset the flag
      globalStore.setUpdatingAccessToken(false);

      // Return a retried request with the new token
      return retryWithNewToken(originalConfig, newToken);
    } catch (refreshError) {
      // Clear the guard timeout
      clearTimeout(tokenRefreshTimeout);

      console.error(`Error refreshing token:`, refreshError);
      // Process the queue with the error
      processQueue(refreshError, null);
      // Reset the flag
      globalStore.setUpdatingAccessToken(false);
      // Sign out on refresh token error
      authStore.setUnauthenticated(router);
      return Promise.reject(refreshError);
    }
  } catch (outerError) {
    console.error(`Critical error in auth flow:`, outerError);
    globalStore.setUpdatingAccessToken(false);
    return Promise.reject(outerError);
  }
};

// Validate the current access token
const validateAccessToken = async (accessToken) => {
  if (!accessToken) {
    console.error("No access token provided for validation");
    return false;
  }

  try {
    // Use a native fetch with a timeout to avoid getting stuck
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 5000); // 5 second timeout

    const response = await fetch(
      `${import.meta.env.VITE_API_HOST}/v3/test/probe`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${accessToken}` },
        signal: controller.signal,
      }
    );

    clearTimeout(timeoutId);

    // Get response status and check if it's ok
    const status = response.status;

    if (!response.ok) {
      if (status === 401) {
        console.error("Token validation failed: Unauthorized (401)");
        return false;
      }

      // Handle server errors
      if (status >= 500) {
        console.error(`Token validation failed: Server error (${status})`);
        // Return true for server errors to avoid unnecessary token refreshes
        return true;
      }

      console.error(`Token validation failed: Status ${status}`);
      return false;
    }

    // Parse the response
    let data;
    try {
      data = await response.json();
    } catch (parseError) {
      console.error("Error parsing validation response:", parseError);
      // If we can't parse but got a 200, assume token is valid
      return response.status === 200;
    }

    // Check if the response contains "success: true"
    if (data && data.success === true) {
      return true;
    } else {
      console.error("Access token validation failed. Response:", data);
      return false;
    }
  } catch (error) {
    if (error.name === "AbortError") {
      console.error("Token validation timed out after 5 seconds");
      // Return true to prevent unnecessary token refreshes on timeout
      return true;
    }

    console.error("Error during token validation:", error);
    // In case of network error, assume token is valid to prevent unnecessary refreshes
    return true;
  }
};

// Retry the original request with a new access token
const retryWithNewToken = async (originalConfig, token) => {
  if (!token) {
    console.error("No token provided for retry");
    throw new Error("No token available for retry");
  }

  try {
    // Instead of cloning, use the original config directly and just update the token
    // This preserves all transformers and other axios internal properties
    originalConfig.headers.Authorization = `Bearer ${token}`;

    // Use apiBackendAuthAxios directly with the updated config

    // Mark as retry to prevent infinite retry loops
    originalConfig._retry = true;

    const response = await apiBackendAuthAxios(originalConfig);

    return response;
  } catch (retryError) {
    console.error(`Retry failed:`, retryError.message);
    throw retryError;
  }
};

// Refresh the access token with up to 3 retries on 500 errors
const refreshAccessToken = async () => {
  const authStore = useAuthStore();
  const globalStore = useGlobalStore();

  // Check if we have a valid refresh token
  if (!authStore.refreshToken) {
    console.error("No refresh token available - cannot refresh access token");
    throw new Error("No refresh token available");
  }

  // Set a timeout to prevent the token refresh from blocking indefinitely
  let refreshTimeout = setTimeout(() => {
    console.error("Token refresh timed out after 15 seconds");
    // Reset the flag to allow future refresh attempts
    globalStore.setUpdatingAccessToken(false);
    // Process any queued requests with an error
    processQueue(new Error("Token refresh timeout"), null);
  }, 15000);

  try {
    // Use the non-intercepted axios instance for the refresh token request
    const response = await apiBackendAxios.post("/auth/refreshtoken", {
      refresh_token: authStore.refreshToken,
    });

    const tokenData = response.data;

    // Validate that we received valid token data
    if (!tokenData || !tokenData.access_token) {
      console.error("Refresh response did not contain valid token data");
      // Make sure we clear the timeout before throwing
      clearTimeout(refreshTimeout);
      refreshTimeout = null;
      throw new Error("Invalid token data received from refresh endpoint");
    }

    // Set the authenticated state with the new token
    const result = await authStore.setAuthenticated(tokenData);
    if (!result) {
      // Make sure we clear the timeout before throwing
      clearTimeout(refreshTimeout);
      refreshTimeout = null;
      throw new Error("Failed to update authentication state with new token");
    }

    // Clear the timeout since the refresh completed successfully
    if (refreshTimeout) {
      clearTimeout(refreshTimeout);
      refreshTimeout = null;
    }

    // Process any queued requests with the new token
    processQueue(null, authStore.accessToken);

    // Return the new access token
    return authStore.accessToken;
  } catch (error) {
    console.error("Error refreshing token:", error);
    // Clear the timeout if it's still active
    if (refreshTimeout) {
      clearTimeout(refreshTimeout);
      refreshTimeout = null;
    }
    // Make sure we process the queue with the error
    processQueue(error, null);
    // Always reset the updating flag on error
    globalStore.setUpdatingAccessToken(false);
    throw error;
  }
};

// Check if the error is a server-side error (500+)
const isServerError = (error) => error.response && error.response.status >= 500;

// Function to manually reset the token refresh state
const resetTokenRefresh = () => {
  const globalStore = useGlobalStore();

  // Clear the queue
  const queueLength = failedQueue.length;

  if (queueLength > 0) {
    processQueue(new Error("Token refresh state manually reset"), null);
  }

  // Reset the updating flag
  if (globalStore.isUpdatingAccessToken) {
    globalStore.setUpdatingAccessToken(false);
  }

  // Reset error count
  countOfErrors = 0;
};

// Function to initialize and reset auth interceptors
const initAuthInterceptors = () => {
  // Reset the token refresh state
  resetTokenRefresh();

  // Validate the current token if available
  const authStore = useAuthStore();
  if (authStore.accessToken) {
    validateAccessToken(authStore.accessToken)
      .then((isValid) => {
        if (!isValid) {
          return refreshAccessToken().catch((error) => {
            console.error(
              "Error refreshing token during initialization:",
              error
            );
            authStore.setUnauthenticated(router);
          });
        }
      })
      .catch((error) => {
        console.error("Error validating token during initialization:", error);
      });
  }
};

export {
  apiBackendAuthAxios,
  apiBackendAxios,
  validateAccessToken,
  refreshAccessToken,
  resetTokenRefresh,
  initAuthInterceptors,
};
