<template>
  <LoadingAnimation v-if="loading && !isNestedFeed" />
  <div
    v-else-if="!isNarrow"
    ref="feed"
    :class="{
      'ml-1 w-full ': isNestedFeed,
    }"
  >
    <div class="right-0 w-full">
      <div class="right-0 w-full">
        <div class="flex items-center align-baseline justify-between">
          <h1 v-if="!isNestedFeed" class="text-black dark:text-white">
            <span class="font-bold">Comments</span>
          </h1>
          <div class="flex items-center">
            <select
              v-if="!isNestedFeed"
              v-model="selectedSortMethod"
              class="ml-1 py-1 px-1 rounded-md bg-white text-black dark:bg-black dark:text-white"
            >
              <option value="popular">Popular</option>
              <option value="unpopular">Unpopular</option>
              <option value="newest">Newest</option>
              <option value="oldest">Oldest</option>
            </select>
          </div>
        </div>
        <DynamicScroller
          v-if="commentFeed && commentFeed.length > 0"
          :items="commentFeed ? commentFeed : []"
          :min-item-size="170"
          :prerender="20"
          :buffer="2000"
        >
          <template #default="{ item, index, active }">
            <DynamicScrollerItem
              v-if="item?.id && item.isPostSkeleton !== true"
              :item="item"
              :key="item.id"
              :active="active"
              :size-dependencies="[
                item.images,
                item.postType,
                item.isRepostWithComment,
              ]"
              :data-index="index"
              :class="isVeryRecentComment(item) ? 'newly-added-comment' : ''"
            >
              <comment-details
                :parentItemId="item.id"
                :index="index"
                :isNestedFeed="true"
                :parentSort="selectedSortMethod"
                :detailShow="detailsShow"
                :depth="depth"
                @comment-id="loadMoreComments"
                @comment-ids="addCommentIds"
                @comment-deleted="removeComment"
              />
            </DynamicScrollerItem>
            <post-skeleton v-else :postId="item?.id ?? 'blank'" />
          </template>
        </DynamicScroller>
        <div v-if="loadingMore" class="flex justify-center pt-3">
          <ion-spinner color="primary" class="text-4xl" />
        </div>
        <div
          v-if="shouldShowLoadMoreButton && !detailsShow"
          class="flex justify-center pt-2"
        >
          <button
            class="cursor-pointer select-none"
            @click="navigateToContent(parentItem.id)"
          >
            <p class="font-bold text-blue-500">Load More</p>
          </button>
        </div>
      </div>
      <!-- <button class="ml-8 cursor-pointer select-none" v-if="shouldShowLoadMoreButton && !detailsShow" @click="loadMoreComments">
      <p class="font-bold text-blue-500">Load More</p>
    </button> -->
    </div>
  </div>
  <div v-else>
    <p
      class="text-primary p-3 cursor-pointer select-none"
      @click="navigateToContent(parentId)"
    >
      View More
    </p>
  </div>
</template>

<script setup>
import {
  ref,
  watch,
  onBeforeMount,
  onMounted,
  computed,
  onBeforeUnmount,
  nextTick,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { usePostStore } from "@/stores/post";
import { useAuthStore } from "@/stores/auth";
import { useGlobalStore } from "@/stores/global";
import CommentDetails from "@/components/comments/CommentDetails.vue";
import { useUserStore } from "../../stores/users";
import LoadingAnimation from "../LoadingAnimation.vue";
import { IonSpinner } from "@ionic/vue";
import { DynamicScroller, DynamicScrollerItem } from "vue-virtual-scroller";
import PostSkeleton from "@/components/posts/PostSkeleton.vue";

// Define properties
const props = defineProps({
  parentItem: {
    type: Object,
    required: true,
  },
  parentId: {
    type: String,
    required: false,
  },
  isNestedFeed: {
    type: Boolean,
    default: false,
  },
  detailsShow: {
    type: Boolean,
    default: false,
  },
  parentFilter: {
    type: Boolean,
    default: null,
  },
  parentSort: {
    type: String,
    defualt: null,
  },
  depth: {
    type: Number,
    default: 0,
  },
  loadMore: {
    type: Boolean,
    default: false,
  },
});

const authStore = useAuthStore();
const postStore = usePostStore();
const globalStore = useGlobalStore();
const userStore = useUserStore();
const commentFeedRef = ref(null);
const userAddedCommentIds = ref(new Set());
const scroller = ref(null);

const commentIds = computed(
  () =>
    postStore.feedMaps[
      `comments-${selectedSortMethod}-${props.parentItem.id}`
    ] ?? []
);
const perPage = computed(() => (props.detailsShow ? 20 : 3));
const subCommentIds = ref([]);
const loading = ref(true);
const loadingMore = ref(false);
const defaultSort = computed(
  () => globalStore.settings.comment_sort || "newest"
);

const selectedSortMethod = ref(
  props.parentItem.postType === "REPLY"
    ? "oldest"
    : props.parentSort ?? defaultSort.value
);

const commentFeedIds = computed(
  () =>
    postStore.feedMaps[
      `comments-${selectedSortMethod.value}-${props.parentItem.id}`
    ] ?? []
);

const commentFeed = computed(() => {
  const feedIds = commentFeedIds.value;
  const feedPosts = feedIds
    .map((id) => postStore.postsCache[id])
    .filter((item) => item !== undefined);

  // If we're in details mode, show all comments
  if (props.detailsShow) {
    return feedPosts;
  }

  // Get recently added comments (last 30 seconds) and user's comments
  const recentlyAddedComments = feedPosts.filter((post) => {
    // If this is the user's own comment or has been manually added by the user
    if (userAddedCommentIds.value.has(post.id)) {
      return true;
    }

    // Or if it's a very recent comment
    if (post.user && post.user.userId === authStore.currentUser?.ulid) {
      const postDate = new Date(post.createdAt);
      const now = new Date();
      const timeDiff = now - postDate; // time difference in milliseconds
      const secondsDiff = timeDiff / 1000; // convert to seconds
      if (secondsDiff < 30) {
        // Comments less than 30 seconds old
        // Add it to our persistent set of user comments to keep

        userAddedCommentIds.value.add(post.id);
        return true;
      }
    }
    return false;
  });

  // If we have recent/user comments, make sure they're included
  if (recentlyAddedComments.length > 0) {
    // Get the IDs of these prioritized comments
    const priorityIds = recentlyAddedComments.map((post) => post.id);
    // Create a set of comment IDs we want to show
    const commentsToShow = new Set();

    // Add the prioritized comments first
    priorityIds.forEach((id) => commentsToShow.add(id));

    // Then add other comments until we reach at least 3 total
    if (commentsToShow.size < 3) {
      for (const post of feedPosts) {
        if (!commentsToShow.has(post.id)) {
          commentsToShow.add(post.id);
          if (commentsToShow.size >= 3) break;
        }
      }
    }

    // Filter the feed to only include the comments we want to show
    const result = feedPosts.filter((post) => commentsToShow.has(post.id));

    return result;
  }

  // Default behavior: show first 3 comments
  const defaultResult = feedPosts.slice(0, 3);

  return defaultResult;
});

const loadMoreComments = (id) => {
  // Load more if the commentId is the last one or the tenth from the bottom
  if (
    commentFeedIds.value.length > 0 &&
    !loadingMore.value &&
    currentPage !== lastPage
  ) {
    const lastId = commentFeedIds.value[commentFeedIds.value.length - 1];
    const tenthFromBottomIndex = commentFeedIds.value.length - 10;
    const tenthFromBottomId =
      commentFeedIds.value[tenthFromBottomIndex] || lastId;

    if (
      (id === lastId || id === tenthFromBottomId) &&
      currentPage <= lastPage
    ) {
      loadingMore.value = true;
      getCommentFeed();
    }
  } else {
    emit("no-more-comments");
  }
};

const commentCount = computed(
  () => props.parentItem.postEngagement.commentCount
);

const emit = defineEmits([
  "comment-ids",
  "scroll-into-view",
  "new-filter",
  "no-more-comments",
  "comments-in-dom",
]);

// 4. Config and Flags
const hasCommentFeed = ref(false);

let observer;
let refreshInterval;

// 5. External Utilities
const router = useRouter();
const route = useRoute();

onBeforeMount(() => {});

onMounted(async () => {
  // Load user comment IDs from localStorage
  try {
    const storageKey = `user-comments-${props.parentItem.id}`;
    const savedCommentIds = localStorage.getItem(storageKey);
    if (savedCommentIds) {
      const parsedIds = JSON.parse(savedCommentIds);
      userAddedCommentIds.value = new Set(parsedIds);
    }
  } catch (e) {
    console.error("Error loading user comments:", e);
  }

  if (props.parentItem.postEngagement.commentCount > 0) {
    await getCommentFeed();

    // If we have a scrollToComment parameter and this post has more comments than what's loaded,
    // proactively load the second page of comments
    if (route.query.scrollToComment && currentPage <= lastPage) {
      await getCommentFeed();
    }
  }

  // Set up observer to track when comments are in view
  nextTick(() => {
    // Get the ref element - could be 'feed' ref or the component root element
    const elementToObserve =
      commentFeedRef.value || document.querySelector(".comment-feed");

    if (elementToObserve) {
      observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (commentIds.value) {
              // Make sure visiblePosts is initialized as an array
              if (!Array.isArray(postStore.visiblePosts)) {
                postStore.visiblePosts = [];
              }

              postStore.visiblePosts = [
                ...postStore.visiblePosts,
                ...commentIds.value,
              ];

              if (subCommentIds.value) {
                const all = [...commentIds.value, ...subCommentIds.value];
                emit("comment-ids", all);
              } else {
                emit("comment-ids", commentIds.value);
              }
            }
          }
        });
      });

      // Begin observing the element
      observer.observe(elementToObserve);

      refreshInterval = setInterval(() => {
        if (commentIds.value) {
          if (subCommentIds.value) {
            const all = [...commentIds.value, ...subCommentIds.value];
            emit("comment-ids", all);
          } else {
            emit("comment-ids", commentIds.value);
          }
        }
      }, 30 * 1000); // Refresh every 30 seconds
    }
  });
});

onBeforeUnmount(() => {
  // Save user comment IDs to localStorage
  try {
    const storageKey = `user-comments-${props.parentItem.id}`;
    localStorage.setItem(
      storageKey,
      JSON.stringify([...userAddedCommentIds.value])
    );
  } catch (e) {
    console.error("Error saving user comments:", e);
  }

  // postStore.stripPostsToSkeleton(commentIds.value);

  // Stop observing the root element of the component
  if (observer) {
    observer.disconnect();
  }

  // Clear the refresh interval
  if (refreshInterval) {
    clearInterval(refreshInterval);
  }
});

const addCommentIds = (ids) => {
  subCommentIds.value = [...subCommentIds.value, ...ids];
};

const removeComment = (id) => {
  commentFeed.value = commentFeed.value.filter((comment) => comment.id !== id);
  // Also remove from our tracking set if present
  if (userAddedCommentIds.value.has(id)) {
    userAddedCommentIds.value.delete(id);
  }
};

// Function to scroll to a specific comment in the dynamic scroller
const scrollToComment = (commentId) => {
  if (!commentId) return;

  // Find the index of the comment in the current feed
  const commentIndex = commentFeed.value.findIndex(
    (comment) => comment.id === commentId
  );

  if (commentIndex !== -1) {
    // Use a slight timeout to ensure the DOM has updated
    setTimeout(() => {
      // If we're using a scroller, use its scrollToItem method
      if (document.querySelector(".vue-recycle-scroller")) {
        const scrollerElement = document.querySelector(".vue-recycle-scroller");
        if (scrollerElement && scrollerElement.__vueParentComponent) {
          const scrollerComponent = scrollerElement.__vueParentComponent.proxy;
          if (
            scrollerComponent &&
            typeof scrollerComponent.scrollToItem === "function"
          ) {
            scrollerComponent.scrollToItem(commentIndex);
          }
        }
      }

      // Add a highlight effect to the comment
      const commentElement = document.querySelector(
        `[data-id="comment-${commentId}"]`
      );
      if (commentElement) {
        commentElement.classList.add("highlight-comment");
        setTimeout(() => {
          commentElement.classList.remove("highlight-comment");
        }, 3000);
      }
    }, 300);
  } else {
    // Optionally trigger more comment loading here
    if (currentPage <= lastPage) {
      getCommentFeed().then(() => {
        // Try again after loading more comments
        setTimeout(() => {
          scrollToComment(commentId);
        }, 500);
      });
    }
  }
};

// Method to add a new comment from the parent component
const handleNewUserComment = (commentId) => {
  if (commentId) {
    // First check if this comment is already in the feed
    const isInFeed = commentFeedIds.value.includes(commentId);

    // Add to our user-tracked comments
    userAddedCommentIds.value.add(commentId);

    // Save immediately to localStorage
    try {
      const storageKey = `user-comments-${props.parentItem.id}`;
      localStorage.setItem(
        storageKey,
        JSON.stringify([...userAddedCommentIds.value])
      );
    } catch (e) {
      console.error("Error saving user comments:", e);
    }

    // If the comment isn't in the feed yet, we might need to refresh
    if (!isInFeed) {
      // If we're not already loading, trigger a refresh
      if (!loading.value && !loadingMore.value) {
        // Reset to first page to make sure we get the latest comments
        currentPage = 1;
        getCommentFeed();
      }
    }

    // Ensure the comment is in the postsCache
    if (!postStore.postsCache[commentId]) {
      // Comment might not be in the store yet, wait a bit longer and check again
      setTimeout(() => {
        // First check again if the comment has appeared in the cache
        if (postStore.postsCache[commentId]) {
          // After tracking, scroll to the new comment
          scrollToComment(commentId);
        } else {
          console.warn(
            "Comment still not found in cache after delay:",
            commentId
          );

          // Check if it's in the feed map at least
          const inFeedMap = commentFeedIds.value.includes(commentId);
          if (inFeedMap) {
            // Try again to reload
            currentPage = 1;
            getCommentFeed();
          }
        }
      }, 1000); // Increase to 1 second for more time
    } else {
      setTimeout(() => {
        scrollToComment(commentId);
      }, 300);
    }
  }
};

// Expose the methods to parent components
defineExpose({
  handleNewUserComment,
  scrollToComment,
});

// Watchers to detect changes in parent props and adjust sorting/filtering accordingly
watch(
  () => props.parentFilter,
  (newVal) => {
    sortComments(selectedSortMethod.value); // Re-sort based on new filter
  }
);

watch(
  () => props.parentSort,
  (newVal) => {
    selectedSortMethod.value = newVal;
    fetchComments(); // Re-fetch comments based on new sort
  }
);

//If it changed, get the comment feed
watch(commentCount, (newCount, oldCount) => {
  // Check if the comment count has changed
  if (newCount !== oldCount) {
    // Fetch the comment feed if the count is greater than 0
    getCommentFeed();
  }
});

watch(
  () => props.loadMore,
  (newVal) => {
    if (newVal && currentPage <= lastPage) {
      getCommentFeed();
    }
  }
);

const commentToPostMap = computed(() => {
  const map = {};
  for (const comment of commentFeed.value) {
    map[comment.id] = comment;
  }
  return map;
});

// Check if the feed is narrow (i.e. depth >= 5)
const isNarrow = computed(() => {
  return props.depth >= 7;
});

//DOM Binding and management
const shouldShowLoadMoreButton = computed(() => {
  // Show the "Load More" button only if there are more comments to load
  // than what's currently being displayed
  const totalCommentCount = props.parentItem.postEngagement?.commentCount || 0;
  const visibleCommentCount = commentFeed.value.length;

  // Only show the load more button if we have more comments than what's visible
  return visibleCommentCount < totalCommentCount && totalCommentCount > 0;
});

const navigateToContent = (postId) => {
  router.push(`/post/${postId}`);
};

let lastPage = 2;
let currentPage = 1;

async function getCommentFeed() {
  if (currentPage > lastPage) {
    emit("no-more-comments");
    return;
  }

  if (currentPage > 1) {
    loadingMore.value = true;
  }

  try {
    // Snapshot current feed to detect changes

    const { last } = await postStore.fetchCommentUlids(
      props.parentItem.id,
      selectedSortMethod.value,
      perPage.value,
      currentPage
    );

    currentPage += 1;
    lastPage = last;

    if (!props.detailsShow) {
      emit("scroll-into-view");
    }

    // Check if we've loaded the last page
    if (currentPage > lastPage) {
      emit("no-more-comments");
    }

    // Wait for the DOM to update before emitting the comments-in-dom event
    // Increased timeout to 500ms to ensure comments are rendered
    setTimeout(async () => {
      // Use nextTick to ensure Vue has finished updating the DOM
      await nextTick();

      emit("comments-in-dom");
    }, 500);
  } catch (error) {
    console.error("Error fetching comments:", error);
    // Also emit no-more-comments on error to avoid infinite loading
    emit("no-more-comments");
  } finally {
    hasCommentFeed.value = true;
    loading.value = false;
    loadingMore.value = false;
  }
}

watch(
  () => selectedSortMethod.value,
  async (newVal) => {
    loading.value = true;
    currentPage = 1;

    // Store existing comments we want to keep visible
    const userCommentsToPreserve = Array.from(userAddedCommentIds.value);

    await getCommentFeed();

    // After fetching, make sure those comments are still tracked
    for (const commentId of userCommentsToPreserve) {
      userAddedCommentIds.value.add(commentId);
    }

    // Save to localStorage
    try {
      const storageKey = `user-comments-${props.parentItem.id}`;
      localStorage.setItem(
        storageKey,
        JSON.stringify([...userAddedCommentIds.value])
      );
    } catch (e) {
      console.error("Error saving user comments:", e);
    }

    if (!props.isNestedFeed) {
      emit("new-filter", newVal);
    }
  }
);

watch(
  () => commentFeed.value.length,
  async (newVal) => {
    if (!props.detailsShow) {
      emit("scroll-into-view");
    }
  }
);

const isVeryRecentComment = (item) => {
  const postDate = new Date(item.createdAt);
  const now = new Date();
  const timeDiff = now - postDate; // time difference in milliseconds
  const secondsDiff = timeDiff / 1000; // convert to seconds
  return secondsDiff < 10; // Comments less than 10 seconds old
};
</script>

<style scoped>
.text-primary {
  color: var(--primary-color);
}

.newly-added-comment {
  animation: highlight-new 4s ease-out;
}

.highlight-comment {
  animation: highlight-pulse 1.5s ease-in-out infinite;
  box-shadow: 0 0 8px rgba(var(--primary-color-rgb, 66, 135, 245), 0.6);
}

@keyframes highlight-new {
  0% {
    background-color: rgba(var(--primary-color-rgb, 66, 135, 245), 0.15);
  }
  100% {
    background-color: transparent;
  }
}

@keyframes highlight-pulse {
  0% {
    background-color: rgba(var(--primary-color-rgb, 66, 135, 245), 0.1);
  }
  50% {
    background-color: rgba(var(--primary-color-rgb, 66, 135, 245), 0.2);
  }
  100% {
    background-color: rgba(var(--primary-color-rgb, 66, 135, 245), 0.1);
  }
}
</style>
