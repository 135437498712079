<template>
  <div
    ref="skeletonRef"
    @click="reloadContent"
    :class="
      commentParent ? '' : 'border-b-4 border-zinc-50 dark:border-gray-500'
    "
    class="relative rounded-md dark:bg-[#1C1C1D] bg-white animate-pulse"
  >
    <ion-icon
      :icon="ellipsisHorizontalSharp"
      class="absolute top-5 right-5 text-gray-300 dark:text-gray-600 animate-pulse"
    />
    <div v-if="isRepost" class="p-2 animate-pulse">
      <div
        class="flex flex-row items-center space-x-2 text-gray-300 dark:text-gray-600"
      >
        <ion-icon
          :icon="repeatSharp"
          class="text-gray-400 dark:text-gray-700 text-2xl"
        />
        <span class="text-sm">reposted by @</span>
        <div class="h-3 bg-gray-300 dark:bg-gray-600 rounded w-1/4"></div>
      </div>
    </div>
    <!-- Header Placeholder -->
    <div class="flex items-center justify-start p-2">
      <div
        class="rounded-full mr-2 bg-gray-300 dark:bg-gray-600 h-10 w-10 flex justify-center items-center"
      >
        <ion-icon
          :icon="personSharp"
          class="text-gray-400 dark:text-gray-700 text-2xl"
        />
      </div>
      <div class="flex-1 py-1 text-gray-300 dark:text-gray-600">
        <div class="h-4 bg-gray-300 dark:bg-gray-600 rounded w-2/3"></div>
        <div class="flex items-center space-x-1 text-mg text-grey-500">
          <span class="align-middle pb-px">@</span>
          <div class="h-3 bg-gray-300 dark:bg-gray-600 rounded w-1/4"></div>
          <span class="align-middle pb-px">•</span>
          <div class="h-3 w-6 bg-gray-300 dark:bg-gray-600 rounded"></div>
        </div>
      </div>
    </div>
    <div
      :class="
        commentParent
          ? 'ml-7 pl-7 pb-7 px-2 border-l border-black dark:border-gray-400 border-t-0 border-r-0 border-b-0'
          : ''
      "
    >
      <!-- Content Placeholder (Title/Body) -->
      <div v-if="body" class="space-y-3 p-2">
        <div class="h-4 bg-gray-300 dark:bg-gray-600 rounded w-4/5"></div>
        <div class="h-4 bg-gray-300 dark:bg-gray-600 rounded"></div>
      </div>

      <!-- Image Placeholder -->
      <div
        v-if="images?.length > 0 || embedUrl"
        class="flex items-center justify-center p-2"
      >
        <div
          v-if="images?.length > 0"
          class="bg-gray-300 dark:bg-gray-600 rounded m-2"
          :style="{ height: `${imgHeight}px`, width: `${imgWidth}px` }"
        ></div>
        <!-- Post Embed Placeholder -->
        <div
          v-else-if="embedUrl"
          class="bg-gray-300 dark:bg-gray-600 rounded w-full aspect-w-16 aspect-h-9 m-2"
        ></div>
      </div>
      <!-- Engagement Bar Placeholder -->
      <div
        class="flex items-center justify-between space-x-4 text-2xl text-gray-300 dark:text-gray-600 p-2"
      >
        <ion-icon :icon="happySharp" />
        <div class="space-x-2">
          <ion-icon :icon="eyeSharp" />
          <ion-icon :icon="chatboxSharp" />
          <ion-icon :icon="arrowRedoSharp" />
          <ion-icon :icon="repeatSharp" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { IonIcon } from "@ionic/vue";
import { usePostStore } from "../../stores/post";
import {
  personSharp,
  happySharp,
  eyeSharp,
  chatboxSharp,
  arrowRedoSharp,
  repeatSharp,
  ellipsisHorizontalSharp,
} from "ionicons/icons";

const props = defineProps({
  images: Array,
  postId: {
    type: String,
  },
  body: String,
  embedUrl: String,
  isRepost: {
    type: Boolean,
    default: false,
  },
  commentParent: {
    type: Boolean,
    default: false,
  },
  feedType: {
    type: String,
  },
  repostUsername: {
    type: String,
  },
});

const postStore = usePostStore();
const skeletonRef = ref(null);
const imgHeight = ref(200);
const imgWidth = ref(500);
let intersectionObserver = null;

onMounted(async () => {
  if (props.images?.length > 0) {
    getImageHeight(props.images[0]);
  }

  // Set up visibility observer for this skeleton
  setupVisibilityObserver();
});

onBeforeUnmount(() => {
  // Clean up visibility observer
  if (intersectionObserver) {
    intersectionObserver.disconnect();
    intersectionObserver = null;
  }
});

// Set up visibility observer for the skeleton
function setupVisibilityObserver() {
  if (
    !props.postId ||
    props.postId === "blank" ||
    !("IntersectionObserver" in window)
  ) {
    return;
  }

  intersectionObserver = new IntersectionObserver(
    async (entries) => {
      const entry = entries[0]; // We're observing only one element

      // Simply update post visibility status - let the store handle the rest
      if (typeof postStore.updatePostVisibility === "function") {
        postStore.updatePostVisibility(props.postId, entry.isIntersecting);
      }
    },
    {
      root: null,
      rootMargin: "50px", // Load a bit before it becomes visible
      threshold: 0.1, // Trigger when 10% visible
    }
  );

  // Start observing this skeleton
  if (skeletonRef.value) {
    intersectionObserver.observe(skeletonRef.value);
  }
}

function reloadContent() {
  if (!postStore.postsCache[props.postId]) return;

  // If post is a skeleton, restore it
  if (postStore.postsCache[props.postId].isSkeleton) {
    postStore.restorePostFromSkeleton(props.postId);
  } else {
    // Force an immediate engagement update
    postStore.forceEngagementUpdate(props.postId);
  }
}

async function getImageHeight(img) {
  if (!img) return;

  const { widthPx: originalWidth, heightPx: originalHeight } = img;
  const maxWidth = skeletonRef.value?.clientWidth || 500;
  const maxHeight = window.innerHeight * 0.5;

  const widthRatio = maxWidth / originalWidth;
  const scaledHeight = originalHeight * widthRatio;

  imgHeight.value = scaledHeight > maxHeight ? maxHeight : scaledHeight;
  imgWidth.value =
    scaledHeight > maxHeight
      ? originalWidth * (maxHeight / originalHeight)
      : maxWidth;
}
</script>
