// postStore.js

import { defineStore } from "pinia";
import { apiBackendAuthAxios } from "@/axiosAuth.js";
import { useUserStore } from "@/stores/users.js";
import { useAuthStore } from "@/stores/auth.js";
import { useGlobalStore } from "@/stores/global.js";
import { randomInsert } from "@/helpers/arrayHelper.js";
import axios from "axios";
import Dexie from "dexie";
import { timeout } from "rxjs";

export const usePostStore = defineStore({
  id: "postStore",
  state: () => ({
    postsCache: {},
    groups: {},
    violationsCache: {},
    visiblePosts: [], // Changed from object to array
    postEngagementQueue: [],
    newPosts: {
      all: [],
      following: [],
      followingMutual: [],
      newUsers: [],
    },
    feedMaps: {
      all: [],
      following: [],
      xposts: [],
      followingMutual: [],
      groups: [],
      profile: [],
      search: [],
      newUsers: [],
      comments: [],
      trending: [],
      influencers: [],
      group: [],
    },
    nextCursor: {
      comments: {},
    },
    groupList: {
      myGroups: [],
      trending: [],
      pinned: [],
      new: [],
    },
    xpostsIndex: 0,
    sliceFeed: 50,
    hasMoreItems: true,
    page: 1,
    cursor: null,
    parentPosts: {},
    grandparentPosts: {},
    rootPosts: {},
    searchedUserUlid: null,
    cancelTokenSource: null,
    refreshFeedMapsOnNextFetch: false,
    trendingHashtags: [],
    fetchTrending: true,
    numberofTrendingHashtagFetches: 0,
    groupsToFetch: [],
    timeoutModalOpen: false,
    timeoutAction: "",
    timeoutUntil: "",
    // Add the new property to track in-flight requests
    inFlightRequests: new Map(),
    pendingPostFetches: [],
    postFetchDebounceTimer: null,
    pendingUserFetchIds: new Set(),
    userFetchDebounceTimer: null,
    // New priority fetching system
    priorityFetchQueue: [],
    normalFetchQueue: [],
    isFetching: false,
    // Track last viewed time for posts
    postLastViewedAt: {},
    // Track posts that need engagement updates
    engagementUpdateQueue: [],
    // Track post visibility duration
    postVisibilityTimers: {},
    // Debounce timer for engagement fetches
    engagementDebounceTimer: null,
    // Minimum time (ms) a post should be visible before fetching engagement
    minVisibilityTimeForEngagement: 800,
    // Whether we're currently fetching engagements
    isFetchingEngagements: false,
    engagementUpdateInterval: 60000, // 60 seconds
  }),
  actions: {
    // Add priority fetching methods
    async scheduleFetch(postIds, isPriority = false) {
      if (!postIds || postIds.length === 0) return;

      // Remove duplicates
      const uniqueIds = [...new Set(postIds)];

      // Filter out posts that are already in cache and not skeletons
      const idsToFetch = uniqueIds.filter(
        (id) => !this.postsCache[id] || this.postsCache[id].isSkeleton
      );

      if (idsToFetch.length === 0) return;

      if (isPriority) {
        this.priorityFetchQueue.push(...idsToFetch);
      } else {
        this.normalFetchQueue.push(...idsToFetch);
      }

      if (!this.isFetching) {
        this.processNextFetch();
      }
    },

    async processNextFetch() {
      if (this.isFetching) return;

      this.isFetching = true;

      try {
        // Process priority queue first
        while (this.priorityFetchQueue.length > 0) {
          // Get unique IDs from the priority queue
          const uniqueIds = [...new Set(this.priorityFetchQueue)];
          this.priorityFetchQueue = [];

          // Get a batch of priority posts
          const batch = uniqueIds.slice(0, 20);
          await this.fetchPostsByIds(batch);

          // If there are more priority posts, add them back to the queue
          if (uniqueIds.length > 20) {
            this.priorityFetchQueue.push(...uniqueIds.slice(20));
          }
        }

        // Then process normal queue
        if (this.normalFetchQueue.length > 0) {
          // Get unique IDs from the normal queue
          const uniqueIds = [...new Set(this.normalFetchQueue)];
          this.normalFetchQueue = [];

          // Get a batch of normal posts
          const batch = uniqueIds.slice(0, 20);
          await this.fetchPostsByIds(batch);

          // If there are more normal posts, add them back to the queue
          if (uniqueIds.length > 20) {
            this.normalFetchQueue.push(...uniqueIds.slice(20));
          }
        }
      } finally {
        this.isFetching = false;

        // Continue processing if there are more items
        if (
          this.priorityFetchQueue.length > 0 ||
          this.normalFetchQueue.length > 0
        ) {
          // Use setTimeout to prevent stack overflow with large queues
          setTimeout(() => this.processNextFetch(), 0);
        }
      }
    },

    // Update post's last viewed time
    updatePostLastViewed(postId) {
      if (!postId) return;
      this.postLastViewedAt[postId] = Date.now();
    },

    // Add the batch signature function to the actions
    getBatchSignature(batchIds) {
      return batchIds.sort().join(",");
    },

    getPostDb() {
      const authStore = useAuthStore();
      const userId = authStore.currentUser?.ulid;
      if (userId) {
        try {
          const postDb = new Dexie(`PostDatabase_${userId}`);
          postDb.version(1).stores({
            posts:
              "id,user,parentUlid,rootUlid,groupId,postType,title,body,detectedLanguage,createdAt,updatedAt,embedUrl,isDeleted,isProcessing,isRepost,isRepostWithComment,images,postEngagement,userEngagement,otherRepostUsers,cachedAt",
            metadata: "key,value",
          });
          return postDb;
        } catch (error) {
          console.error(`Failed to open IndexedDB: ${error}`);
          throw error;
        }
      }
    },

    deletePostDb() {
      const authStore = useAuthStore();
      const userId = authStore.currentUser?.ulid;
      if (userId) {
        Dexie.delete(`PostDatabase_${userId}`)
          .then(() => {})
          .catch((error) => {
            console.error(`Failed to delete IndexedDB: ${error}`);
          });
      } else {
        console.error("User ID is undefined or null");
      }
    },

    // Action to strip post down to skeletons
    stripPostToSkeleton(postId) {
      const post = this.postsCache[postId];
      if (
        post &&
        !post.isSkeleton &&
        !(post.postType === "COMMENT" || post.postType === "REPLY")
      ) {
        // Check if post is not already a skeleton
        const skeleton = {
          body: post.body !== "" ? "..." : null, // Placeholder body
          embedUrl: post.embedUrl !== "" ? "placeholder" : null, // Placeholder embed URL
          id: post.id,
          images: post.images,
          isSkeleton: true, // Mark as skeleton
          // Keep other essential properties
        };
        this.postsCache[postId] = skeleton;
      }
    },

    // Action to restore post from skeletons
    async restorePostFromSkeleton(postId) {
      if (!postId || !this.postsCache[postId]) return false;

      // Skip if not a skeleton
      if (!this.postsCache[postId].isSkeleton) return true;

      try {
        // Try to get from IndexedDB first
        const cachedPost = await this.getPostFromIndexDB(postId);

        if (cachedPost) {
          // Update cache with data from IndexedDB
          this.postsCache[postId] = {
            ...this.postsCache[postId],
            ...cachedPost,
            isSkeleton: false,
            cachedAt: new Date().toISOString(),
          };

          // Important: Don't queue for engagement update here
          // Let the visibility system handle engagement updates

          return true;
        } else {
          // If not in IndexedDB, add to fetch queue
          if (!this.pendingPostFetches) {
            this.pendingPostFetches = [];
          }

          if (!this.pendingPostFetches.includes(postId)) {
            this.pendingPostFetches.push(postId);
          }

          // Debounce API fetch
          if (!this.postFetchDebounceTimer) {
            this.postFetchDebounceTimer = setTimeout(async () => {
              const batchToFetch = [...this.pendingPostFetches];
              this.pendingPostFetches = [];

              await this.fetchPostsByIds(batchToFetch);
              this.postFetchDebounceTimer = null;
            }, 50); // 50ms debounce
          }
        }
      } catch (error) {
        console.error(`Error restoring post ${postId} from skeleton:`, error);
        return false;
      }
    },

    async getPostFromIndexDB(postId) {
      const postDb = this.getPostDb();
      if (!postDb) return null; // Early exit if the DB isn't available

      try {
        const post = await postDb.table("posts").get(postId);
        return post || null; // Return the post if found, or null if not
      } catch (error) {
        console.error(`Failed to get post from IndexedDB: ${error}`);
        return null; // Return null to handle errors gracefully
      }
    },

    async updatePostInDB(post, updateLastWatched = true) {
      if (post.isSkeleton) {
        return;
      }

      // Initialize the postDb
      const postDb = this.getPostDb();

      try {
        if (post.images.length > 0 && updateLastWatched) {
          let lastWatchedByIndex = [];

          // Check IndexedDB for images that have been watched
          const cachedPost = await postDb
            .table("posts")
            .where("id")
            .equals(post.id)
            .first();

          if (cachedPost && cachedPost.images) {
            // Store the lastWatched values in an array based on the index of the image
            lastWatchedByIndex = cachedPost.images.map(
              (image) => image.lastWatched
            );
          }

          // Update the images with the lastWatched values if they exist
          post.images = post.images.map((image, index) => {
            if (lastWatchedByIndex[index] !== undefined) {
              image.lastWatched = lastWatchedByIndex[index];
            }
            return image;
          });
        }

        // Clone the post and prepare it for caching in IndexedDB
        const clonedPost = JSON.parse(JSON.stringify(post)); // Strip non-serializable values
        clonedPost.cachedAt = new Date().toISOString();

        // Store the updated post back into IndexedDB
        await postDb.table("posts").put(clonedPost);
      } catch (error) {
        console.error(`Failed to update posts from IndexedDB: ${error}`);
        throw error;
      }
    },

    async deletePostInDB(postId) {
      try {
        const postDb = this.getPostDb();
        await postDb.table("posts").delete(postId);
      } catch (error) {
        console.error(`Failed to delete post from IndexedDB: ${error}`);
        throw error;
      }
    },

    async deletePostsInDB(postIds) {
      try {
        const postDb = this.getPostDb();
        await postDb.table("posts").bulkDelete(postIds);
      } catch (error) {
        console.error(`Failed to delete posts from IndexedDB: ${error}`);
        throw error;
      }
    },
    async clearStaleData() {
      try {
        const postDb = this.getPostDb();

        const threshold = new Date();
        threshold.setHours(threshold.getHours() - 72); // 72 hours ago

        if (!postDb.table("posts")) {
          return;
        }

        // Delete posts older than 72 hours
        await postDb
          .table("posts")
          .where("cachedAt")
          .below(threshold.toISOString())
          .delete();
      } catch (error) {
        console.error(`Failed to clear stale data from IndexedDB: ${error}`);
      }
    },

    handleTimeout(timeoutUntil, action) {
      this.timeoutModalOpen = true;
      this.timeoutAction = action;
      this.timeoutUntil = timeoutUntil;
    },

    async createPost(postData, isRepost) {
      const authStore = useAuthStore();

      const method = isRepost ? "post" : "put";
      const endpoint = isRepost ? `/posts/repost` : `/posts`;

      if (!isRepost) {
        postData.groupName = postData.groupName || "default";
        this.postEngagementQueue.push(postData.ulid);
        if (this.postEngagementQueue.length > 5) {
          this.fetchPostEngagements(this.postEngagementQueue);
          this.postEngagementQueue = [];
        }
      }

      if (isRepost && postData.body === "" && postData.images.length === 0) {
        const rePostData = {
          ulid: postData.ulid,
          title: "repost",
        };
        this.createRePost(
          rePostData,
          postData.ulid,
          authStore.currentUser.ulid
        );
      }

      return apiBackendAuthAxios[method](endpoint, postData)
        .then(async (response) => {
          const post = isRepost ? response.data : response.data.data;

          if (postData.images) {
            post.images = postData.images.map((url) => ({ url }));
          }

          // Include OpenGraph data if it exists in the postData
          if (postData.openGraph) {
            post.openGraph = postData.openGraph;
          }

          if (!isRepost) {
            post.user.userId = authStore.currentUser.ulid;
          }
          if (postData.groupId) {
            this.feedMaps[`group-${postData.groupId}`] = [
              post.id,
              ...this.feedMaps[`group-${postData.groupId}`],
            ];

            return post;
          } else if (postData.groupName && postData?.groupName !== "default") {
            this.feedMaps[`group-${postData.groupName}`] = [
              post.id,
              ...this.feedMaps[`group-${postData.groupName}`],
            ];

            return post;
          }

          if (
            postData.groupId &&
            (this.groups[postData.groupId]?.visibility === "PRIVATE" ||
              this.groups[postData.groupId]?.visibility === "CHAT")
          ) {
            return post;
          }
          this.addToFeedMap(post);

          return post;
        })
        .catch((error) => {
          console.error("Error creating post: ", error);
          if (error.response?.data?.error?.timeout_until) {
            this.handleTimeout(
              error.response.data.error.timeout_until,
              isRepost ? "quote" : "post"
            );
          }
          return error;
        });
    },

    async createRePost(rePostData, parentItem, userId) {
      if (!userId) {
        console.error("No user ID provided to createRePost");
        return;
      }

      try {
        const response = await apiBackendAuthAxios.post(
          `/posts/repost`,
          rePostData
        );

        if (response.response?.data?.error?.timeout_until) {
          this.handleTimout(
            response.response.data.error.timeout_until,
            "repost"
          );
          return error;
        }
        let post = response.data;

        //change the post type to repost
        post.postType = "REPOST";

        post.parentUlid = parentItem.id;

        post.user.userId = userId;

        // Update the repost in the local cache
        this.postsCache[post.id] = post;
        await this.updatePostInDB(post); // Update IndexedDB with the new repost

        this.fetchPostEngagements([parentItem.id]);

        if (post.groupName === "default") {
          this.addToFeedMap(post);
        }
        // update the parent post with the new repost
        let parentPost = this.postsCache[parentItem.id];
        parentPost.userEngagement.hasReposted = true;

        this.postsCache[parentItem.id] = parentPost;

        return post;
      } catch (error) {
        if (error.response?.data?.error?.timeout_until) {
          this.handleTimeout(error.response.data.error.timeout_until, "repost");
        }
        return error;
      }
    },

    async addComment(
      commentData,
      parentId,
      grandParentId,
      rootUlid,
      userId,
      commentSort,
      replyId
    ) {
      try {
        const response = await apiBackendAuthAxios.put(
          `/comments`,
          commentData
        );

        const comment = response.data.data;

        // Include OpenGraph data if it exists in the commentData
        if (commentData.openGraph) {
          comment.openGraph = commentData.openGraph;
        }

        const updatedComment = {
          ...comment,
          parentUlid: replyId ? grandParentId : parentId,
          rootUlid: rootUlid,
          grandparentUlid: grandParentId,
          user: {
            userId: userId,
          },
        };

        const postIds = new Set([parentId, grandParentId, rootUlid]);

        // Increment comment count on parent posts
        if (parentId && this.postsCache[parentId]) {
          // Increment comment count for the direct parent
          const parent = this.postsCache[parentId];
          if (parent.postEngagement) {
            parent.postEngagement.commentCount =
              (parent.postEngagement.commentCount || 0) + 1;
            parent.postEngagement.totalCommentCount =
              (parent.postEngagement.totalCommentCount || 0) + 1;

            // Update the post in cache and DB
            this.updatePostInDB(parent);
          }
        }

        // If this is a reply to a comment, increment the comment count on grandparent post as well
        if (
          grandParentId &&
          grandParentId !== parentId &&
          this.postsCache[grandParentId]
        ) {
          const grandParent = this.postsCache[grandParentId];
          if (grandParent.postEngagement) {
            // Only increment totalCommentCount for the grandparent
            grandParent.postEngagement.totalCommentCount =
              (grandParent.postEngagement.totalCommentCount || 0) + 1;

            // Update the post in cache and DB
            this.updatePostInDB(grandParent);
          }
        }

        // Initialize the feedMap for comments if it does not exist
        if (!this.feedMaps[`comments-${commentSort}-${parentId}`]) {
          this.feedMaps[`comments-${commentSort}-${parentId}`] = [];
        }

        // Add the new comment to the feed based on sort method and whether it's a reply
        if (!replyId) {
          // For direct comments to the parent post
          if (
            !this.feedMaps[`comments-${commentSort}-${parentId}`].includes(
              comment.id
            )
          ) {
            // Add to appropriate position based on sort
            if (commentSort === "newest") {
              // For newest sort, add to the beginning
              this.feedMaps[`comments-${commentSort}-${parentId}`].unshift(
                comment.id
              );
            } else {
              // For other sorts (oldest, popular), add to the end
              this.feedMaps[`comments-${commentSort}-${parentId}`].push(
                comment.id
              );
            }
          }
        } else {
          //insert the comment feed under the replyId
          const feedMapKey = `comments-${commentSort}-${parentId}`;
          const currentFeedMap = [...this.feedMaps[feedMapKey]];

          // Find the index of the parent comment (replyId) in the feed map
          const parentIndex = currentFeedMap.findIndex((id) => id === replyId);

          if (parentIndex !== -1) {
            // If we found the parent comment, insert the new comment right after it
            currentFeedMap.splice(parentIndex + 1, 0, comment.id);

            // Update the feed map with the new order
            this.feedMaps[feedMapKey] = currentFeedMap;
          } else {
            // If we can't find the parent comment, add to the beginning (newest) or end (oldest)
            // based on the sort method
            if (commentSort === "newest") {
              this.feedMaps[feedMapKey] = [comment.id, ...currentFeedMap];
            } else {
              this.feedMaps[feedMapKey] = [...currentFeedMap, comment.id];
            }
          }
        }

        // Filter out null values from postIds before fetching engagements
        await this.fetchPostEngagements(
          Array.from(postIds).filter((id) => id !== null),
          false
        );

        return updatedComment;
      } catch (error) {
        if (error.response?.data?.error?.timeout_until) {
          this.handleTimeout(
            error.response.data.error.timeout_until,
            "comment"
          );
        }
        return error;
      }
    },

    cleanGiphyURL(url) {
      const baseURL = url.split(".gif")[0];
      return baseURL + ".gif";
    },

    handleError(error) {
      console.error(error);
      switch (error.response?.data?.error_type) {
        case "authentication":
          return "Authentication Failed";
        case "Too many requests":
          return "Too many attempts, try again in a few minutes";
        default:
          return "Uh oh, something went wrong, try again";
      }
    },

    addToFeedMap(post) {
      const authStore = useAuthStore();
      const isInfluencer =
        authStore.currentUser.badges.includes("gold_verified");
      // Update feedMaps
      this.feedMaps.all.unshift(post.id);
      this.feedMaps.following.unshift(post.id);
      this.feedMaps.followingMutual.unshift(post.id);
      if (isInfluencer) {
        this.feedMaps.influencers.unshift(post.id);
      }
    },

    async pinPost(id, action) {
      try {
        let response;

        if (action === "add") {
          response = await apiBackendAuthAxios.put(`/posts/pinned`, {
            ulid: id,
          });
        } else if (action === "delete") {
          response = await apiBackendAuthAxios.delete(`/posts/pinned`, {
            data: {
              ulid: id,
            },
          });
        }
        if (response?.data) {
          return { success: true, data: response.data }; // If you want to return the data
        }

        return { success: true }; // If you don't care about the response data
      } catch (error) {
        console.error(
          "API Error:",
          error.response ? error.response.data : error.message
        );
        return {
          success: false,
          message: error.response
            ? error.response.data.message
            : "Unknown error",
        };
      }
    },
    async getPinnedPost(username) {
      try {
        const response = await apiBackendAuthAxios.post(
          "/posts/pinned/username",
          {
            username: username,
          }
        );
        return response.data.ulid;
      } catch (error) {
        console.error(error);
        return null; // Return null if there's an error
      }
    },

    async postInvalidation(postIds) {
      postIds.forEach((id) => {
        delete this.postsCache[id];
      });
    },

    async removePostFromCache(postId) {
      if (!postId) return;

      // First check if this is a recent comment that should be preserved
      const post = this.postsCache[postId];
      if (post) {
        // Check if it's a recently added comment (less than 60 seconds old)
        const now = new Date();
        const postDate = new Date(post.createdAt);
        const timeDiffSeconds = (now - postDate) / 1000;

        // If it's a recent post (less than 60 seconds old) or it's a comment
        if (
          timeDiffSeconds < 60 ||
          post.postType === "REPLY" ||
          post.postType === "COMMENT"
        ) {
          // Get the current user ID
          const authStore = useAuthStore();
          // Extra protection for user's own comments
          if (post.user && post.user.userId === authStore.currentUser?.ulid) {
            console.log(
              `Protecting user's own comment from removal: ${postId}`
            );
            return; // Don't remove the user's own comment
          }
        }
      }

      // Remove from memory cache
      if (this.postsCache[postId]) {
        delete this.postsCache[postId];
      }

      // Remove from all feed maps
      Object.keys(this.feedMaps).forEach((mapKey) => {
        if (Array.isArray(this.feedMaps[mapKey])) {
          this.feedMaps[mapKey] = this.feedMaps[mapKey].filter(
            (id) => id !== postId
          );
        }
      });

      // Remove from IndexedDB
      try {
        await this.deletePostInDB(postId);
      } catch (error) {
        console.error(`Failed to delete post ${postId} from IndexedDB:`, error);
      }
    },

    async removePostsFromCache(userId) {
      const postsToRemoveFromIndexDb = [];

      // Iterate over the keys of postsCache
      for (let postId in this.postsCache) {
        // If the post's userId is equal to the userId passed to the function, delete the post
        if (this.postsCache[postId].user?.userId === userId) {
          // Remove post from the in-memory cache
          delete this.postsCache[postId];

          // Add the post ID to the array of posts to remove from IndexedDB
          postsToRemoveFromIndexDb.push(postId);

          // Remove all post IDs from the feedMaps
          Object.keys(this.feedMaps).forEach((mapKey) => {
            this.feedMaps[mapKey] = this.feedMaps[mapKey].filter(
              (item) => item !== postId
            );
          });
        }
      }

      // Remove the posts from IndexedDB
      try {
        await this.deletePostsInDB(postsToRemoveFromIndexDb);
      } catch (error) {
        console.error(
          `Failed to delete posts from IndexedDB for userId ${userId}:`,
          error
        );
      }
    },

    async fetchCommentUlids(postId, sort, perPage = 20, currentPage = null) {
      try {
        const response = await apiBackendAuthAxios.post(
          `/comments?page=${currentPage}`,
          {
            ulid: postId,
            per_page: perPage,
            sort_by: sort,
          }
        );

        const commentKey = sort + "-" + postId;

        this.processFeedMap("comments", response.data, commentKey, sort);

        return {
          last: response.data.last_page,
        };
      } catch (error) {
        console.error(error);
        return {
          commentUlids: [],
          nextCursor: null,
        };
      }
    },

    async viewPosts(postIds) {
      // Remove duplicate post IDs and "END"
      if (postIds.length === 0) {
        return;
      }

      const uniquePostIds = [
        ...new Set(
          postIds.filter(
            (id) => id !== "END" && id !== null && id !== undefined
          )
        ),
      ];
      try {
        await apiBackendAuthAxios.post("/posts/views", {
          ulids: uniquePostIds,
        });

        for (const postId of uniquePostIds) {
          if (this.postsCache[postId]) {
            this.postsCache[postId].postEngagement.views += 1;
          }

          this.updatePostInDB(this.postsCache[postId]);
        }
      } catch (error) {
        console.error("Failed to record views for posts: ", error);
      }
    },

    async isProcessing() {
      const globalStore = useGlobalStore();

      // Remove nulls
      globalStore.processingPosts = globalStore.processingPosts.filter(
        (item) => item !== null
      );

      const tempPosts = globalStore.processingPosts;

      if (tempPosts.length === 0) {
        return [];
      }

      try {
        const response = await apiBackendAuthAxios.post("/posts/map", {
          ulids: tempPosts,
        });
        const posts = response.data.data;
        const notProcessingPosts = posts.filter((post) => !post.isProcessing);

        // Update the posts in the local cache and DB
        if (notProcessingPosts.length === 0) {
          return;
        }

        notProcessingPosts.forEach((post) => {
          this.postsCache[post.id] = post;
          this.updatePostInDB(post);
        });

        // Remove the processed posts from the tempPosts
        globalStore.processingPosts = tempPosts.filter(
          (ulid) => !notProcessingPosts.some((post) => post.id === ulid)
        );
      } catch (error) {
        console.error("Error fetching or processing posts:", error);
        // Handle the error appropriately
      }
    },

    async fetchViolations(postIds) {
      // Remove duplicate post IDs and "END"
      postIds = [...new Set(postIds.filter((id) => id !== "END"))];

      try {
        const response = await apiBackendAuthAxios.post(
          "/posts/mapViolations",
          {
            ulids: postIds,
          }
        );

        const violations = response.data.data;
        violations.forEach((violation) => {
          this.violationsCache[violation.id] = violation;
        });

        return violations;
      } catch (error) {
        console.error(error);
        return [];
      }
    },

    // Helper: Check the cache and IndexedDB for a given postId
    async checkCacheAndIndexDB(postId, postsCache) {
      if (postsCache[postId] && !postsCache[postId].isSkeleton) {
        // Post is in cache and not a skeleton

        return postsCache[postId];
      } else {
        // Check IndexedDB for the post
        try {
          const post = await this.getPostFromIndexDB(postId);
          // If post is found in IndexedDB, update the cache
          if (post) {
            postsCache[postId] = post;
          }
          return post || null; // If post is not found, return null
        } catch (error) {
          console.error(`Error checking IndexedDB for post: ${postId}`, error);
          return null; // Return null on error
        }
      }
    },

    // Helper: API call to fetch posts given an array of post IDs
    async fetchPostsFromAPI(batchIds, retryCount = 0) {
      if (batchIds.length === 0) {
        return [];
      }

      // Filter out null, undefined, or empty string values
      const validBatchIds = batchIds.filter((id) => id && id !== "");

      if (validBatchIds.length === 0) {
        return [];
      }

      try {
        const response = await apiBackendAuthAxios.post("/posts/map", {
          ulids: validBatchIds,
        });

        const newPosts = response.data.data;

        // Process any hidden posts and posts not returned by the API
        this.checkForHiddenPosts(newPosts, validBatchIds);
        this.cleanupMissingPosts(validBatchIds, newPosts);

        return newPosts; // Return the posts data from the response
      } catch (error) {
        // Implement exponential backoff for network or server errors
        const MAX_RETRIES = 3;
        if (
          retryCount < MAX_RETRIES &&
          (!error.response || // Network error
            error.response.status >= 500 || // Server error
            error.response.status === 429)
        ) {
          // Rate limit
          const delay = Math.pow(2, retryCount) * 1000; // Exponential backoff: 1s, 2s, 4s

          await new Promise((resolve) => setTimeout(resolve, delay));
          return this.fetchPostsFromAPI(batchIds, retryCount + 1);
        }

        // For client errors or after max retries, log and return empty array
        console.error(`Failed to fetch posts from API:`, error);
        return []; // Return empty array on error
      }
    },

    /**
     * Cleanup posts that were requested but not returned by the API
     * This ensures our feed maps don't reference posts that no longer exist
     * @param {string[]} requestedIds - Array of post IDs that were requested
     * @param {Object[]} returnedPosts - Array of post objects that were returned
     */
    cleanupMissingPosts(requestedIds, returnedPosts) {
      // If there's no difference in count, quick check before proceeding
      if (!requestedIds || !returnedPosts || requestedIds.length === 0) {
        return;
      }

      // Get IDs of returned posts as a Set for faster lookups
      const returnedIds = new Set(returnedPosts.map((post) => post.id));

      // Find IDs that were requested but not returned
      const missingIds = requestedIds.filter((id) => !returnedIds.has(id));

      if (missingIds.length === 0) {
        return;
      }

      // Current time for checking recently added posts
      const now = new Date();

      // Use our existing method to clean up all feed maps
      missingIds.forEach((postId) => {
        // Check if the post exists in cache first
        if (this.postsCache[postId]) {
          const post = this.postsCache[postId];

          // IMPORTANT: Don't remove recently added comments/posts
          // If the post was created in the last minute, don't remove it
          // This protects comments that were just added by the user
          const postDate = new Date(post.createdAt);
          const timeDiffSeconds = (now - postDate) / 1000;

          // If it's a recent post (less than 60 seconds old) or it's a comment, preserve it
          if (
            timeDiffSeconds < 60 ||
            post.postType === "REPLY" ||
            post.postType === "COMMENT"
          ) {
            console.log(
              `Preserving recent post/comment from cleanup: ${postId}`
            );
            return; // Skip this post, don't remove it
          }

          // Use the removePostFromCache method to cleanly remove the post
          this.removePostFromCache(postId);
        } else {
          // If the post isn't in cache, we still need to clean up feed maps
          Object.keys(this.feedMaps).forEach((feedKey) => {
            if (Array.isArray(this.feedMaps[feedKey])) {
              this.feedMaps[feedKey] = this.feedMaps[feedKey].filter(
                (feedId) => feedId !== postId
              );
            }
          });
        }
      });
    },

    async checkForHiddenPosts(response, postIds) {
      if (
        !response ||
        !postIds ||
        response.length === 0 ||
        postIds.length === 0
      ) {
        return response;
      }

      // Get IDs of returned posts
      const returnedPostIds = new Set(response.map((post) => post.id));

      // Find posts that were requested but not returned
      const missingPostIds = postIds.filter((id) => !returnedPostIds.has(id));

      // Remove missing posts
      if (missingPostIds.length > 0) {
        missingPostIds.forEach((postId) => {
          // Check if post exists and is hidden
          const post = this.postsCache[postId];
          if (post && post.isHidden) {
            this.removePostFromCache(postId);
          }
        });
      }

      return response;
    },

    // Helper: Update cache and IndexedDB with new posts
    updateCacheAndIndexDB(newPosts) {
      try {
        newPosts.forEach(async (post) => {
          this.postsCache[post.id] = post; // Update the in-memory cache

          await this.updatePostInDB(post); // Update the post in IndexedDB
        });
      } catch (error) {
        console.error(
          "Error updating cache and IndexedDB with new posts:",
          error
        );
      }
    },

    // Map to track in-flight requests by batch signature

    async fetchPostsByIds(postIds, update = false, group = true) {
      if (!postIds || postIds.length === 0) {
        return [];
      }

      const userStore = useUserStore();

      // Remove duplicate post IDs, nulls, and "END" and sort in review id(ulid order)
      const uniquePostIds = [
        ...new Set(
          postIds.filter((id) => id !== "END" && id !== null && id !== "")
        ),
      ].sort();

      if (uniquePostIds.length === 0) {
        return [];
      }

      let cachedPostIds = [];

      if (!update) {
        const cachedPosts = await Promise.all(
          uniquePostIds.map((postId) =>
            this.checkCacheAndIndexDB(postId, this.postsCache)
          )
        );

        //get postIds for cached posts
        cachedPostIds = cachedPosts.map((post) => post?.id).filter(Boolean);
      }

      // Filter out posts that are already in cache
      let stillMissing = uniquePostIds.filter(
        (postId) => !cachedPostIds.includes(postId)
      );

      // First, initialize post skeletons
      if (stillMissing.length > 0) {
        stillMissing.forEach((postId) => {
          if (postId && !this.postsCache[postId]) {
            this.postsCache[postId] = this.initializePostSkeleton(postId);
          }
        });
      }

      // Fetch missing posts from the API
      if (stillMissing.length > 0) {
        // Filter out posts that are currently being fetched by other requests
        const currentlyFetchingIds = Array.from(this.inFlightRequests.keys());

        // Remove IDs that are already being fetched
        stillMissing = stillMissing.filter(
          (postId) => !currentlyFetchingIds.includes(postId)
        );

        if (stillMissing.length > 0) {
          // Fetch posts in batches
          const globalStore = useGlobalStore();
          // Get batch size based on connection speed
          const BATCH_SIZE = globalStore.getBatchSize();

          const batchPromises = [];

          // Mark these IDs as being fetched
          stillMissing.forEach((id) => {
            this.inFlightRequests.set(id, true);
          });

          try {
            for (let i = 0; i < stillMissing.length; i += BATCH_SIZE) {
              const batch = stillMissing.slice(i, i + BATCH_SIZE);
              if (batch.length > 0) {
                const promise = this.fetchPostsFromAPI(batch);
                batchPromises.push(promise);
              }
            }

            // Wait for all batches to complete
            const allNewPosts = await Promise.all(batchPromises);

            // Flatten the array of arrays and update cache
            const flattenedPosts = allNewPosts.flat();
            this.updateCacheAndIndexDB(flattenedPosts);
          } finally {
            // Clean up the in-flight tracking
            stillMissing.forEach((id) => {
              this.inFlightRequests.delete(id);
            });
          }
        }

        // For posts that were already being fetched by other calls,
        // we wait a small amount of time for them to complete
        if (currentlyFetchingIds.length > 0) {
          const idsToWaitFor = uniquePostIds.filter(
            (id) =>
              currentlyFetchingIds.includes(id) && !cachedPostIds.includes(id)
          );

          if (idsToWaitFor.length > 0) {
            // Wait a bit for other requests to complete (max 1 second)
            await new Promise((resolve) => setTimeout(resolve, 1000));
          }
        }
      }

      const allPosts = uniquePostIds
        .map((postId) => this.postsCache[postId])
        .filter(Boolean);

      if (cachedPostIds && cachedPostIds.length > 0) {
        const engagementToFetch =
          this.postEngagementQueue.concat(cachedPostIds);

        if (engagementToFetch.length > 19) {
          this.fetchPostEngagements(engagementToFetch);
          this.postEngagementQueue = this.postEngagementQueue.filter(
            (id) => !engagementToFetch.includes(id)
          );
        }
      }

      // Handle group fetching
      if (group) {
        let groupIds = [];
        allPosts.forEach((post) => {
          if (
            post.groupId &&
            post.groupId !== "01gssz7xsrqzth07cstd90t179" &&
            !this.groups[post.groupId]
          ) {
            groupIds.push(post.groupId);
          }
        });

        if (groupIds.length > 0) {
          // remove null
          groupIds = groupIds.filter((id) => id !== null);
          this.fetchGroups(groupIds);
        }
      }

      // Batch user fetching with debounce to prevent duplicate requests
      if (!this.pendingUserFetchIds) {
        this.pendingUserFetchIds = new Set();
      }

      const userIds = [
        ...new Set(allPosts.map((post) => post?.user?.userId).filter(Boolean)),
      ];

      // Only add new user IDs not already in the pending set
      userIds.forEach((id) => this.pendingUserFetchIds.add(id));

      // Use debounce to batch user fetch requests
      if (!this.userFetchDebounceTimer) {
        this.userFetchDebounceTimer = setTimeout(async () => {
          // Convert Set to Array for fetching
          const userIdsToFetch = [...this.pendingUserFetchIds];
          this.pendingUserFetchIds.clear();

          if (userIdsToFetch.length > 0) {
            await userStore.fetchUsersByIds(userIdsToFetch);
          }

          this.userFetchDebounceTimer = null;
        }, 100); // 100ms debounce
      }

      // Return the posts in the order they were requested
      return allPosts;
    },

    initializePostSkeleton(postId) {
      return {
        id: postId,
        isSkeleton: true,
        images: ["initial-placeholder"],
        initialFetch: true,
      };
    },

    async fetchPostEngagements(postIds, filter = true) {
      if (!postIds || postIds.length === 0) return;

      // First, filter out any null or undefined values
      postIds = postIds.filter((id) => id !== null && id !== undefined);

      if (postIds.length === 0) return;

      // Filter postIds to avoid unnecessary requests
      const postsToFetch = filter
        ? postIds.filter((postId) => {
            const post = this.postsCache[postId];

            // Skip if post doesn't exist in cache
            if (!post) return false;

            // Skip skeletons
            if (post.isSkeleton) return false;

            // Skip if engagement data is recent
            if (post.lastEngagementUpdate) {
              const now = Date.now();
              const timeSinceLastUpdate = now - post.lastEngagementUpdate;
              // Only update engagements every 60 seconds (or specified interval)
              if (timeSinceLastUpdate < this.engagementUpdateInterval) {
                return false;
              }
            }

            return true;
          })
        : postIds;

      if (postsToFetch.length === 0) return;

      const MAX_BATCH_SIZE = 20; // Adjust based on API limits

      // Process in batches if needed
      for (let i = 0; i < postsToFetch.length; i += MAX_BATCH_SIZE) {
        const batch = postsToFetch.slice(i, i + MAX_BATCH_SIZE);

        try {
          const response = await apiBackendAuthAxios.post("/posts/engagement", {
            ulids: batch,
          });

          if (response.data.success) {
            const results = response.data.data;
            const timestamp = Date.now();

            // Update post engagements
            for (const postId in results) {
              // Skip if post no longer exists in cache
              if (!this.postsCache[postId]) continue;

              const post = this.postsCache[postId];
              const engagementData = results[postId];

              // Update engagements in memory
              post.postEngagement = {
                ...(post.postEngagement || {}),
                ...engagementData,
              };

              // Mark when we last updated engagements for this post
              post.lastEngagementUpdate = timestamp;

              // Store updated post to IndexedDB
              this.updatePostEngagementsInDB(engagementData, false, postId);
            }
          }
        } catch (error) {
          console.error("Error fetching post engagements:", error);
        }
      }

      return true;
    },

    // Helper function to update only the engagement data of a post in IndexedDB
    async updatePostEngagementsInDB(engagements, view = true, PostId) {
      try {
        let viewedPosts = [];

        // Now update engagements
        for (const engagement of engagements) {
          const existingPost = this.postsCache[engagement.id];
          if (existingPost && !existingPost.isSkeleton) {
            // Always update the post with the new engagement information
            existingPost.postEngagement = engagement.postEngagement;
            existingPost.updatedAt = engagement.updatedAt;
            if (existingPost.userReaction === null) {
              existingPost.userReaction = engagement.userReaction;
            }
            existingPost.isDeleted = engagement.isDeleted;
            existingPost.otherRepostUsers = engagement.otherRepostUsers;

            // Remove the post from the cache if it is hidden
            if (engagement.isHidden) {
              this.removePostsFromCache(existingPost.id);
              return;
            }

            // Update the cached post
            this.postsCache[engagement.id] = existingPost;

            // Update the post in IndexedDB
            this.updatePostInDB(existingPost);

            viewedPosts.push(engagement.id);
          }
        }

        // Finally, view the posts to update engagement metrics
        if (view) {
          // this.viewPosts(viewedPosts);
        }
      } catch (error) {
        console.error("Error updating post engagements in IndexedDB:", error);
      }
    },

    async deletePost(id, repost = false) {
      const post = this.postsCache[id];

      if (repost) {
        Object.keys(this.feedMaps).forEach((key) => {
          this.feedMaps[key] = this.feedMaps[key].filter((item) => item !== id);
        });

        return { success: true };
      }

      try {
        await apiBackendAuthAxios
          .delete("/posts/delete", {
            params: {
              ulid: id,
            },
          })
          .then(async () => {
            await this.deletePostInDB(id);

            delete this.postsCache[id];

            this.fetchPostEngagements([post.parentUlid]);

            // Loop through each key in feedMaps and filter out the post ID
            Object.keys(this.feedMaps).forEach((key) => {
              this.feedMaps[key] = this.feedMaps[key].filter(
                (item) => item !== id
              );
            });

            return { success: true };
          })
          .catch((error) => {
            console.error("error", error);
            return { success: false };
          });
      } catch (error) {
        console.error(error);
        // Return the error response
        return { success: false };
      }
    },

    clearFollowingFeedMap() {
      this.feedMaps.following = [];
      this.feedMaps.followingMutual = [];
    },

    clearPostCache() {
      (this.postsCache = {}),
        (this.violationsCache = {}),
        (this.feedMaps = {
          all: [],
          following: [],
          xposts: [],
          followingMutual: [],
          profile: [],
          search: [],
          newUsers: [],
          comments: [],
          trending: [],
          influencers: [],
        });
    },

    async checkUsers(posts) {
      const userStore = useUserStore();

      const userIds = [...new Set(posts.map((item) => item.userId))];
      await userStore.fetchUsersByIds(userIds);
    },
    /*
     * This checkUsersPosts function collects missing user details from
     * a given set of posts' parents and updates a user store with the missing user information.
     */
    async checkUsersPosts(posts) {
      const userStore = useUserStore();

      // Extract the user IDs from the posts
      const userIds = Object.values(posts)
        .map((post) => post?.user?.userId) // Directly map to the userId of each post
        .filter((userId) => userId !== null && userId !== undefined); // Filter out null and undefined

      // Fetch the users; fetchUsersByIds will check cache, then IndexedDB, then API.
      await userStore.fetchUsersByIds(userIds);
    },

    async updatePostReaction(postId, reactionType, action) {
      try {
        let response;
        // API call to add or delete the post reaction
        if (action === "add") {
          response = await apiBackendAuthAxios.put(`/post/reaction`, {
            ulid: postId,
            reaction: reactionType,
          });
        } else if (action === "delete") {
          response = await apiBackendAuthAxios.delete(`/post/reaction`, {
            data: { ulid: postId, reaction: reactionType },
          });
        }
        if (response.data.success) {
          return { success: true };
        } else {
          return { success: false };
        }
      } catch (error) {
        console.error(error);
        return { success: false };
      }
    },

    async getxPostsFeedMap() {
      try {
        const response = await apiBackendAuthAxios.get("/xposts/all");
        let xPosts = response.data.data;
        this.checkUsers(xPosts);
        const newFeed = xPosts.map((feedItem) => feedItem.ulid);
        this.feedMaps.xposts = newFeed;
        return this.feedMaps.xposts;
      } catch (error) {
        console.error(error);
      }
    },

    async searchHashtags(query) {
      try {
        // Create a cancellation token source
        const cancelTokenSource = axios.CancelToken.source();

        // Store the cancellation token source in the store state
        this.cancelTokenSource = cancelTokenSource;

        const response = await apiBackendAuthAxios.post(
          "/search/autocomplete/hashtag",
          {
            hashtag: query,
          },
          {
            cancelToken: cancelTokenSource.token,
          }
        );

        const { data } = response;
        return data;
      } catch (error) {
        if (axios.isCancel(error)) {
          // Request was cancelled
          console.error("Request cancelled:", error.message);
        } else {
          console.error("Error searching hashtags:", error);
        }
        return []; // Return an empty array if there's an error
      }
    },
    async searchTickers(query) {
      try {
        // Create a cancellation token source
        const cancelTokenSource = axios.CancelToken.source();

        // Store the cancellation token source in the store state
        this.cancelTokenSource = cancelTokenSource;

        const response = await apiBackendAuthAxios.post(
          "/search/autocomplete/ticker",
          {
            ticker: query,
          },
          {
            cancelToken: cancelTokenSource.token,
          }
        );

        const { data } = response;
        return data;
      } catch (error) {
        if (axios.isCancel(error)) {
          // Request was cancelled
          console.error("Request cancelled:", error.message);
        } else {
          console.error("Error searching tickers:", error);
        }
        return []; // Return an empty array if there's an error
      }
    },

    // Add a new action to cancel the current search request
    cancelSearch() {
      if (this.cancelTokenSource) {
        // Cancel the current request using the cancellation token
        this.cancelTokenSource.cancel("Search request cancelled");
        // Reset the cancellation token source
        this.cancelTokenSource = null;
      }
    },

    consolidatedPosts(posts) {
      let repostCounts = {};
      let consolidatedPosts = [];
      let nonReposts = [];
      let olderPostUlids = []; // Array to store ulids of older posts

      for (let post of posts) {
        if (post.isRepost && !post.isRepostWithComment) {
          if (repostCounts[post.parentUlid]) {
            repostCounts[post.parentUlid].count++;
            repostCounts[post.parentUlid].repostUsernames.push(post.username);
            if (post.id > repostCounts[post.parentUlid].recentPost.id) {
              olderPostUlids.push(
                repostCounts[post.parentUlid].recentPost.parentUlid
              ); // Add ulid of older post
              repostCounts[post.parentUlid].recentPost = post;
            } else {
              olderPostUlids.push(post.parentUlid); // Add ulid of older post
            }
          } else {
            repostCounts[post.parentUlid] = {
              count: 1,
              recentPost: post,
              repostUsernames: [post.username],
            };
          }
        } else {
          nonReposts.push(post);
        }
      }

      for (let parentUlid in repostCounts) {
        let repost = repostCounts[parentUlid].recentPost;
        repost.additionalReposts = repostCounts[parentUlid].count - 1;
        repost.repostUsernames = repostCounts[parentUlid].repostUsernames;
        consolidatedPosts.push(repost);
      }

      // Combine consolidatedPosts and nonReposts
      const allPosts = [...consolidatedPosts, ...nonReposts];

      // Sort posts
      allPosts.sort((a, b) => {
        if (a.id < b.id) return 1;
        if (a.id > b.id) return -1;
        return 0;
      });

      return { consolidatedPosts: allPosts, removedReposts: olderPostUlids };
    },

    async processFeedMap(feedType, response, suffix = "", sort = "newest") {
      const { data, next_cursor } = response;
      const feedKey = feedType + (suffix ? `-${suffix}` : "");
      const newFeed = data.map((feedItem) => feedItem.ulid);

      this.fetchPostsByIds(newFeed);

      //Create Skeletons
      //     if (feedType !== 'comments') {
      //     newFeed.forEach((ulid) => {
      //       if (!this.postsCache[ulid]) {
      //         this.postsCache[ulid] = this.initializePostSkeleton(ulid);
      //       }
      //     });
      //  }
      const parentUlids = data
        .filter((item) => item.parentUlid)
        .map((item) => item.parentUlid);

      // Fetch details for parentUlids if they exist
      if (parentUlids.length > 0) {
        // parentUlids.forEach((ulid) => {
        //   if (!this.postsCache[ulid]) {
        //     this.postsCache[ulid] = this.initializePostSkeleton(ulid);
        //   }
        // });
        this.fetchParents(parentUlids);
      }

      this.checkUsers(data);
      this.checkAndUpdateUsers(data);

      const existingFeedMap = this.feedMaps[feedKey] || [];
      const updatedFeedMap = [...existingFeedMap, ...newFeed];

      const globalStore = useGlobalStore();

      let filteredFeedMap;
      // Remove reported posts from the feedMap
      if (feedType !== "violations") {
        filteredFeedMap = updatedFeedMap.filter(
          (ulid) => !globalStore.reportedPosts.includes(ulid)
        );
      } else {
        filteredFeedMap = updatedFeedMap;
      }
      if (feedType === "comments") {
        //only remove duplicates for comments
        if (sort === "newest") {
          // Remove duplicates and sort by ulid
          this.feedMaps[feedKey] = [...new Set(filteredFeedMap)].sort(
            this.compareUlid
          );
        } else if (sort === "oldest") {
          // Remove duplicates and sort by ulid
          this.feedMaps[feedKey] = [...new Set(filteredFeedMap)]
            .sort(this.compareUlid)
            .reverse();
        } else if (sort === "popular" || sort === "unpopular") {
          // Remove duplicates and sort by newFeed order
          this.feedMaps[feedKey] = [...new Set(filteredFeedMap)];
        }
      } else {
        // Remove duplicates and sort by ULID
        this.feedMaps[feedKey] = [...new Set(filteredFeedMap)].sort(
          this.compareUlid
        );
      }

      return { feedMap: this.feedMaps[feedKey], next_cursor };
    },

    async fetchParents(parentUlids) {
      const parentPosts = await this.fetchPostsByIds(parentUlids, false, false);

      // If parent post is repost_with_comment, comment and reply then fetch the parent of the parent if not already in parentPosts
      const grandparentUlids = parentPosts
        .filter(
          (post) =>
            post.isRepostWithComment ||
            post.postType === "COMMENT" ||
            post.postType === "REPLY"
        )
        .map((post) => post.parentUlid);

      // Remove duplicates and ulids already present in parentUlids
      const uniqueGrandparentUlids = grandparentUlids.filter(
        (ulid) => !parentUlids.includes(ulid)
      );

      this.fetchPostsByIds(uniqueGrandparentUlids);
    },

    async checkAndUpdateUsers(feedmap) {
      const userStore = useUserStore(); // Import the user store as needed

      // Create an array to store user IDs that need to be updated
      let usersToUpdate = [];

      const allUsers = new Map();

      for (const item of feedmap) {
        const user = await userStore.getUser(item.userId);
        if (user) {
          // Check if the user information is available
          const userUpdatedAt = item.userUpdatedAtEpoch;
          const lastUpdatedAt = user.updatedAtEpoch;

          // Add the user to the allUsers map
          allUsers.set(item.userId, {
            userId: item.userId,
            lastActiveAt: item.lastActiveAt,
          });

          if (userUpdatedAt > lastUpdatedAt) {
            // If userUpdatedAt is greater, add the user ID to the update array
            usersToUpdate.push(item.userId);
          }
        }
      }

      for (let [userId, user] of allUsers) {
        userStore.updateLastActiveAt(userId, user.lastActiveAt);
      }

      if (usersToUpdate.length > 0) {
        //Depulicate the usersToUpdate array and remove nulls undefined ect
        usersToUpdate = [
          ...new Set(usersToUpdate.filter((item) => item !== null)),
        ];
        // Fetch all the users in one batch
        await userStore.fetchUsersFromApi(usersToUpdate);
      }
    },

    async checkForNewPosts(feedType, lastUlid) {
      // Only proceed for the specified feedTypes
      if (
        !["all", "following", "followingMutual", "newUsers"].includes(feedType)
      ) {
        console.warn(`Unsupported feedType: ${feedType}`);
        return;
      }

      // Validate the lastUlid for the input feedType
      if (lastUlid === null) {
        console.warn(`No lastUlid found`);
        return;
      }

      try {
        const response = await apiBackendAuthAxios.post(`/posts/${feedType}`, {
          ulid: lastUlid,
        });
        if (response.data.data.length > 0) {
          const { data, next_cursor } = response.data;
          this.checkAndUpdateUsers(data);

          return response.data.data;
        }
      } catch (error) {
        console.error(`Error fetching new posts for ${feedType}:`, error);
      }
    },

    async checkForNewPostsPusher() {
      const globalStore = useGlobalStore();
      const authStore = useAuthStore();
      const currentFeed = globalStore.currentFeed;

      // Check if the current feed is relevant for new posts checking
      if (["profile", "search", "group"].includes(currentFeed)) {
        return; // Early exit if the feed is not one of the targeted types
      }

      // Check if feedMaps has an entry for the current feed and validate the last ULID
      if (!this.feedMaps[currentFeed]) {
        console.warn(`No feed map available for ${currentFeed}`);
        return;
      }

      const lastUlid = this.feedMaps[currentFeed][0];
      if (lastUlid === "END") {
        this.feedMaps[currentFeed] = []; // Clear feed map if last ULID is 'END'
        return;
      }

      const endpoint =
        currentFeed === "violations"
          ? `/moderation/posts/${currentFeed}`
          : `/posts/${currentFeed}`;

      try {
        const response = await apiBackendAuthAxios.post(endpoint, {
          ulid: lastUlid,
        });
        if (response.data.data.length > 0) {
          const { data } = response.data;
          this.checkAndUpdateUsers(data);

          const existingPostUlids = this.collectExistingPostUlids(
            this.newPosts,
            this.feedMaps,
            currentFeed
          );
          const uniqueNewPosts = this.filterNewPosts(
            data,
            existingPostUlids,
            authStore.currentUser.ulid
          );

          this.newPosts[currentFeed] = [
            ...(this.newPosts[currentFeed] || []),
            ...uniqueNewPosts,
          ];

          return data; // Return the new posts for further processing
        }
      } catch (error) {
        console.error(`Error fetching new posts for ${currentFeed}:`, error);
        // Optionally throw an error or return an error status
        throw new Error(`Failed to fetch new posts for ${currentFeed}`);
      }
    },

    async fetchNewGroupPosts(groupId, lastUlid = null) {
      const authStore = useAuthStore();
      try {
        // Handle lastUlid when it's null or undefined
        if (!lastUlid) {
          lastUlid = this.feedMaps[`group-${groupId}`][0];
        }

        if (lastUlid === "END" || lastUlid === null) {
          return;
        }

        const currentFeed = `group-${groupId}`;

        const response = await apiBackendAuthAxios.post(`/posts/group`, {
          groupId: groupId,
          ulid: lastUlid,
        });
        if (response.data.data.length > 0) {
          const { data } = response.data;
          this.checkAndUpdateUsers(data);

          const existingPostUlids = this.collectExistingPostUlids(
            this.newPosts,
            this.feedMaps,
            currentFeed
          );
          const uniqueNewPosts = this.filterNewPosts(
            data,
            existingPostUlids,
            authStore.currentUser.ulid
          );

          this.newPosts[currentFeed] = [
            ...(this.newPosts[currentFeed] || []),
            ...uniqueNewPosts,
          ];

          return data; // Return the new posts for further processing
        } else {
          return []; // Return an empty array if no new posts are found
        }
      } catch (error) {
        console.error(`Error fetching new posts for group ${groupId}:`, error);
        throw new Error(`Failed to fetch new posts for group ${groupId}`);
      }
    },

    // Fetch a feed of posts from a group
    async fetchGroupFeedMap(groupName, cursor) {
      //check if groupName is a ULID
      const ulidRegex = /^[0123456789ABCDEFGHJKMNPQRSTVWXYZ]{26}$/;

      // Check if groupName is a ULID
      const isUlid = ulidRegex.test(groupName.toUpperCase());

      const payload = isUlid
        ? { groupId: groupName }
        : { groupName: groupName };

      const params = cursor ? { cursor } : {};
      try {
        const response = await apiBackendAuthAxios.post(
          "/posts/group",
          payload,
          { params }
        );

        return this.processFeedMap("group", response.data, groupName);
      } catch (error) {
        return this.handleApiError(error);
      }
    },

    async fetchGroupList(type) {
      try {
        const requestData = type !== "new" ? { type } : {};

        const response = await apiBackendAuthAxios.post(
          "/groups/search",
          requestData
        );

        let groups = response.data.data;

        if (this.groupList[type].length) {
          groups = groups.filter(
            (group) => !this.groupList[type].includes(group.id)
          );

          this.groupList[type] = [
            ...this.groupList[type],
            ...groups.map((group) => group.id),
          ];
        } else {
          this.groupList[type] = groups.map((group) => group.id);
        }

        this.fetchGroups(groups.map((group) => group.id));

        return response.data.data;
      } catch (error) {
        console.error(error);
      }
    },

    async fetchGroups(groupIds, update = false) {
      if (this.groupsToFetch.length > 0) {
        groupIds = [...new Set([...this.groupsToFetch, ...groupIds])];
      }

      //remvove groups that are already in the groups cache
      groupIds = groupIds.filter((groupId) => !this.groups[groupId]);

      if (groupIds.length === 0) {
        return [];
      }

      try {
        const response = await apiBackendAuthAxios.post("/groups/map", {
          ulids: groupIds,
        });
        let groups = response.data.data;

        // Update the groupCache with the new groups
        groups.forEach((group) => {
          this.groups[group.id] = group;
        });

        groups.forEach((group) => {
          if (group.isJoined && !this.groupList.myGroups.includes(group.id)) {
            this.groupList.myGroups.push(group.id);
          }
        });

        // Remove the fetched groups from the groupsToFetch array
        this.groupsToFetch = this.groupsToFetch = [];

        return groups;
      } catch (error) {
        console.error(error);
      }
    },

    async joinGroup(groupId, userId) {
      const userStore = useUserStore();

      try {
        const response = await apiBackendAuthAxios.post("/group/join", {
          groupId,
        });

        this.groups[groupId].isJoined = true;

        // Ensure the member count exists and increment it
        if (this.groups[groupId].membersCount != null) {
          this.groups[groupId].membersCount += 1;
        } else {
          this.groups[groupId].membersCount = 1;
        }

        // Add the current user ID to groupMembers
        if (userStore.groupMembers[groupId]) {
          userStore.groupMembers[groupId].push(userId);
        } else {
          userStore.groupMembers[groupId] = [userId];
        }

        // Add the group to the groupList.myGroups
        this.groupList.myGroups.push(groupId);

        this.feedMaps.groups = [];
        this.fetchFeedMap("groups");

        return response.data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },

    async leaveGroup(groupId, userId) {
      const userStore = useUserStore();

      try {
        const response = await apiBackendAuthAxios.delete("/group/join", {
          data: { groupId },
        });

        this.groups[groupId].isJoined = false;

        // Ensure the member count exists and decrement it without going below zero
        if (
          this.groups[groupId].membersCount != null &&
          this.groups[groupId].membersCount > 0
        ) {
          this.groups[groupId].membersCount -= 1;
        } else {
          this.groups[groupId].membersCount = 0;
        }

        // Check if groupMembers exists for the groupId before filtering
        if (userStore.groupMembers && userStore.groupMembers[groupId]) {
          userStore.groupMembers[groupId] = userStore.groupMembers[
            groupId
          ].filter((memberId) => memberId !== userId);
        }

        //Remove the group from the groupList.myGroups and groupList.pinned
        this.groupList.myGroups = this.groupList.myGroups.filter(
          (group) => group !== groupId
        );
        this.groupList.pinned = this.groupList.pinned.filter(
          (group) => group !== groupId
        );

        this.feedMaps.groups = [];

        return response.data;
      } catch (error) {
        console.error(error);
      }
    },

    async deletePostFromGroup(groupId, postId) {
      try {
        const response = await apiBackendAuthAxios.delete("/groups/post", {
          data: { groupId, postId },
        });
        this.removePostsFromCache(postId);
        this.feedMaps[`group-${groupId}`] = this.feedMaps[
          `group-${groupId}`
        ].filter((post) => post !== postId);
        return response.data;
      } catch (error) {
        console.error(error);
      }
    },

    async removeMemberFromGroup(groupId, userId) {
      try {
        const response = await apiBackendAuthAxios.post("/group/ban", {
          data: { groupId, userId },
        });
        return response.data;
      } catch (error) {
        console.error(error);
      }
    },

    async togglePinGroup(groupId, isPinned) {
      const method = isPinned === "unpin" ? "delete" : "post";
      const url = "/group/pin";

      try {
        let response;
        if (method === "delete") {
          response = await apiBackendAuthAxios.delete(url, {
            data: { groupId: groupId },
          });
        } else {
          response = await apiBackendAuthAxios.post(url, { groupId: groupId });
        }
        this.groups[groupId].isPinned = !isPinned;

        if (isPinned === "unpin") {
          this.groupList.pinned = this.groupList.pinned.filter(
            (group) => group !== groupId
          );
        } else if (isPinned === "pin") {
          this.groupList.pinned.push(groupId);
        }

        return response;
      } catch (error) {
        console.error("error", error);
        return error;
      }
    },

    async joinChat(groupName) {
      try {
        const response = await apiBackendAuthAxios.post("/group/join", {
          groupName,
        });
        return response.data;
      } catch (error) {
        console.error(error);
      }
    },

    async createChat(payload) {
      const authStore = useAuthStore();

      const { body, images = [], groupName, visibility = "CHAT" } = payload;

      try {
        // Step 1: Create a private group
        const groupPayload = {
          name: groupName,
          visibility,
        };
        await apiBackendAuthAxios.put("/group/add", groupPayload);
        // Step 2: Send the first message in the created group
        const messagePayload = {
          groupName,
          body,
          images,
        };
        const response = await apiBackendAuthAxios.put(
          "/posts",
          messagePayload
        );
        // Process response data
        const post = response.data.data;
        if (images.length > 0) {
          post.images = images.map((url) => ({ url }));
        }
        post.user.userId = authStore.currentUser.ulid;
        // Add post to IndexedDB and local cache
        await this.updatePostInDB(post);
        this.postsCache[post.id] = post;

        this.feedMaps[`group-${groupName}`] = [post.id];

        return post;
      } catch (error) {
        this.handleError(error);
      }
    },

    async createGroup(payload, update = false) {
      // set put or patch based on update
      const method = update ? "patch" : "put";

      const route = update ? "/group/edit" : "/group/add";

      try {
        const response = await apiBackendAuthAxios[method](route, payload);

        // Update the groupList with the new group

        if (!update) {
          this.groupList.myGroups.push(response.data.id);
        } else {
          this.groupList.myGroups = this.groupList.myGroups.filter(
            (group) => group !== response.data.id
          );
        }

        this.groups[response.data.id] = response.data;

        return response.data;
      } catch (error) {
        return error;
      }
    },

    async deleteGroup(groupId) {
      try {
        const response = await apiBackendAuthAxios.delete("/group", {
          params: { groupId },
        });

        // Remove the group from the groupList and groups cache
        this.groupList.myGroups = this.groupList.myGroups.filter(
          (group) => group !== groupId
        );
        this.groupList.pinned = this.groupList.pinned.filter(
          (group) => group !== groupId
        );
        this.groups[groupId] = null;

        return response.data;
      } catch (error) {
        console.error(error);
      }
    },

    async fetchChatList() {
      try {
        const response = await apiBackendAuthAxios.get("/chat/groups");

        this.chatList = response.data.data;
        return response.data.data;
      } catch (error) {
        console.error(error);
      }
    },

    // Utility function to collect existing ULIDs from newPosts and feedMaps
    collectExistingPostUlids(newPosts, feedMaps, feed) {
      const newPostUlids = new Set(newPosts[feed]?.map((post) => post.ulid));
      const feedMapUlids = new Set(feedMaps[feed]?.map((post) => post.ulid));
      return new Set([...newPostUlids, ...feedMapUlids]); // Merge both sets
    },

    // Function to filter out posts that already exist or are from the current user
    filterNewPosts(posts, existingUlids, currentUserUlid) {
      return posts.filter(
        (post) => !existingUlids.has(post.ulid) && post.ulid !== currentUserUlid
      );
    },

    compareUlid(a, b) {
      return b.localeCompare(a);
    },
    handleApiError(error) {
      console.error(error);
      return { feedMap: [], next_cursor: null };
    },
    async fetchFeedMap(feedType, cursor) {
      const params = cursor ? { cursor } : {};
      try {
        const response = await apiBackendAuthAxios.get("/posts/" + feedType, {
          params,
        });

        return this.processFeedMap(feedType, response.data);
      } catch (error) {
        return this.handleApiError(error);
      }
    },
    async fetchModerationFeedMap(feedType, cursor) {
      const params = cursor ? { cursor } : {};
      try {
        const response = await apiBackendAuthAxios.post(
          "/moderation/posts/violations",
          { params }
        );

        return this.processFeedMap(feedType, response.data);
      } catch (error) {
        return this.handleApiError(error);
      }
    },
    async fetchUserFeedMap(
      feedType,
      username,
      searchType,
      page,
      pageSize,
      cursor
    ) {
      const encodedUsername = encodeURIComponent(username);

      const params = cursor ? { cursor } : {};
      if (searchType === "media") {
        try {
          const response = await apiBackendAuthAxios.get(
            "/profile/" + encodedUsername + "/feedImagesOnly",
            { params }
          );
          const suffix = `${username}-${searchType}`;
          return this.processFeedMap(feedType, response.data, suffix);
        } catch (error) {
          return this.handleApiError(error);
        }
      } else if (searchType === "comments") {
        try {
          const response = await apiBackendAuthAxios.get(
            "/profile/" + encodedUsername + "/feedCommentsOnly",
            {
              params: {
                cursor,
              },
            }
          );
          const suffix = `${username}-${searchType}`;
          return this.processFeedMap(feedType, response.data, suffix);
        } catch (error) {
          return this.handleApiError(error);
        }
      } else {
        try {
          const response = await apiBackendAuthAxios.get(
            "/profile/" + encodedUsername + "/feed",
            {
              params: {
                cursor,
              },
            }
          );
          return this.processFeedMap(feedType, response.data, username);
        } catch (error) {
          return this.handleApiError(error);
        }
      }
    },
    // Fetch a feed based on a search
    async searchFeedMap(feedType, searchType, query, page, pageSize, cursor) {
      const params = cursor ? { cursor } : {};

      // Handler for individual search type
      const searchByType = async (type) => {
        const payloadKey = type === "username" ? "ulid" : type;

        const payload = type === "username" ? this.searchedUserUlid : query;
        try {
          const response = await apiBackendAuthAxios.post(
            `/search/posts/${type}`,
            { [payloadKey]: payload },
            { params }
          );
          const suffix = `${type}-${query}`;
          return this.processFeedMap(feedType, response.data, suffix);
        } catch (error) {
          return this.handleApiError(error);
        }
      };

      if (searchType === "posts") {
        // Perform all searches in parallel
        try {
          let allSearchTypes = ["username", "hashtag"];

          const allResults = await Promise.all(
            allSearchTypes.map((type) => searchByType(type))
          );

          // Combine all feedmaps into one, deduplicate if necessary
          const combinedFeedMap = allResults.reduce((acc, result) => {
            if (Array.isArray(result.feedMap)) {
              result.feedMap.forEach((post) => {
                if (!acc.includes(post)) {
                  acc.push(post);
                }
              });
            }
            return acc;
          }, []);

          //Order by ULID
          combinedFeedMap.sort(this.compareUlid);

          const suffix = `${searchType}-${query}`;

          const feedKey = "search" + (suffix ? `-${suffix}` : "");

          this.feedMaps[feedKey] = combinedFeedMap;

          // Optionally, sort combinedFeedMap if needed, e.g., by post date

          return {
            feedMap: combinedFeedMap,
            next_cursor: allResults[0].next_cursor,
          };
        } catch (error) {
          return this.handleApiError(error);
        }
      } else {
        // Handle single search type
        return searchByType(searchType);
      }
    },

    async fetchTrendingPosts(cursor = null) {
      try {
        const response = await apiBackendAuthAxios.get(
          "/trending/posts/last24",
          { params: { cursor: cursor } }
        );

        if (response.data.posts.length === 0) {
          return [];
        }

        const limitedPosts = response.data.posts.slice(0, 20);

        this.feedMaps.trending = limitedPosts;

        this.fetchPostsByIds(limitedPosts);

        return {
          feedMap: limitedPosts,
          next_cursor: response.data.next_cursor || null,
        };
      } catch (error) {
        console.error(error);
        return [];
      }
    },

    async fetchTrendingHashtags() {
      this.numberofTrendingHashtagFetches += 1;

      if (this.numberofTrendingHashtagFetches > 3) {
        this.fetchTrending = true;
        this.numberofTrendingHashtagFetches = 0;
      }

      if (this.trendingHashtags.length > 0 || !this.fetchTrending) {
        return this.trendingHashtags;
      }
      try {
        const response = await apiBackendAuthAxios.get(
          "/trending/hashtags/last24"
        );

        this.trendingHashtags = response.data.hashtags;

        return response.data.hashtags;
      } catch (error) {
        console.error(error);
        return [];
      }
    },

    /**
     * Constructs a unique feed key based on the given parameters.
     *
     * @param {string} feedType - The type of feed.
     * @param {string} feedParams - The parameters specific to the feedType.
     * @param {string} searchType - The type of search.
     *
     * @return {string} The constructed unique feed key.
     */
    constructFeedKey(feedType, feedParams, searchType) {
      let uniqueFeedKey = feedType;

      if (feedType === "search" && searchType) {
        uniqueFeedKey = `${feedType}-${searchType}-${feedParams}`;
      } else if (
        feedType === "profile" &&
        feedParams &&
        searchType === "media"
      ) {
        uniqueFeedKey = `${feedType}-${feedParams}-${searchType}`;
      } else if (feedType === "profile" && feedParams) {
        uniqueFeedKey = `${feedType}-${feedParams}`; // Assuming feedParams here is the username
      }

      return uniqueFeedKey;
    },

    /**
     * Fetches missing posts from the given feed.
     *
     * @param {Array} feed - The feed containing post IDs.
     * @return {Promise} - A promise that resolves when the missing posts are fetched.
     */
    async fetchMissingPosts(feed) {
      const missingPostIds = feed.filter((postId) => !this.postsCache[postId]);
      if (missingPostIds.length > 0) {
        await this.fetchPostsByIds(missingPostIds);
      }
    },

    /**
     * Returns an array of XPost feeds based on the xpostsIndex provided.
     * @return {Array} - An array of XPost feeds.
     */
    getXPostFeedArray() {
      if (!this.feedMaps.xposts || this.feedMaps.xposts.length === 0) {
        return [];
      }

      return Object.entries(this.postsCache)
        .filter(([postId]) => this.feedMaps.xposts.includes(postId))
        .map(([, post]) => post);
    },

    /**
     * Returns an array of normal post objects from the provided feed.
     *
     * @param {string[]} feed - An array of post IDs representing the feed.
     * @returns {Object[]} - An array of normal post objects from the provided feed.
     */
    getNormalPostFeedArray(feed) {
      const normalPosts = Object.entries(this.postsCache)
        .filter(([postId]) => feed.includes(postId))
        .map(([, post]) => post);

      normalPosts.sort((a, b) => b.id.localeCompare(a.id));

      return normalPosts;
    },

    /**
     * Fetches data from the store.
     *
     * @param {string} feedType - The type of feed.
     * @param {object} feedParams - The parameters for the feed.
     * @param {string} searchType - The type of search.
     * @param {string} next_cursor - The next cursor.
     * @return {object} An object containing the fetched posts and the next cursor.
     */
    async fetchFromStoreData(feedType, feedParams, searchType, next_cursor) {
      // Construct a unique feed key based on the given parameters
      const uniqueFeedKey = this.constructFeedKey(
        feedType,
        feedParams,
        searchType
      );

      // Get the feed by the unique feed key
      let feed = this.feedMaps[uniqueFeedKey];

      // If there are no posts in the feed, fetch the feed data, or feed is undefined
      if (!feed || feed?.length < 1) {
        // Fetch the feed data
        next_cursor = (
          await this.fetchFeedData(
            feedType,
            feedParams,
            searchType,
            1,
            this.sliceFeed,
            null,
            next_cursor
          )
        ).next_cursor;
        feed = this.feedMaps[uniqueFeedKey];
      }

      // If there are no posts in the feed, return an empty array
      if (!feed) {
        return [];
      }
      // Sort the feed by ulid
      feed.sort(this.compareUlid);
      // Fetch missing posts
      await this.fetchMissingPosts(feed);
      // Get the XPost feeds
      const xPostsFeedArray = this.getXPostFeedArray();
      // Get the normal post feeds
      const normalFeedArray = this.getNormalPostFeedArray(feed);

      // Check if the normal feed has less than 10 posts
      if (normalFeedArray.length < 10) {
        // If it has less than 10 posts, don't insert XPost feeds
        return { posts: normalFeedArray, next_cursor: next_cursor };
      }

      // Randomly insert the XPost feeds into the normal post feeds
      let posts =
        normalFeedArray.length > 0
          ? randomInsert(
              normalFeedArray,
              xPostsFeedArray,
              normalFeedArray.length / 50,
              normalFeedArray.length / 10
            )
          : [];
      // Return the posts and the next cursor

      return { posts: posts, next_cursor: next_cursor };
    },

    // Function to fetch the feedmap by type
    async fetchFeedMapByType(
      feedType,
      feedParams,
      searchType,
      page,
      pageSize,
      cursor
    ) {
      let feedMap, next_cursor;

      // Fetch the feed map based on the feed type
      switch (feedType) {
        case "profile":
          ({ feedMap, next_cursor } = await this.fetchUserFeedMap(
            feedType,
            feedParams,
            searchType,
            page,
            pageSize,
            cursor
          ));
          break;
        case "search":
          ({ feedMap, next_cursor } = await this.searchFeedMap(
            feedType,
            searchType,
            feedParams,
            page,
            pageSize,
            cursor
          ));
          break;
        case "group":
          ({ feedMap, next_cursor } = await this.fetchGroupFeedMap(
            feedParams,
            cursor
          ));
          break;
        case "trending":
          ({ feedMap, next_cursor } = await this.fetchTrendingPosts(cursor));

          break;

        case "violations":
          ({ feedMap, next_cursor } = await this.fetchModerationFeedMap(
            feedType,
            cursor
          ));
          break;

        default:
          ({ feedMap, next_cursor } = await this.fetchFeedMap(
            feedType,
            cursor
          ));
      }

      return { feedMap, next_cursor };
    },
    /*
     * Fetches the feed data for the given feed type
     */
    async fetchFeedData(
      feedType,
      feedParams,
      searchType,
      initialPosition,
      page,
      pageSize,
      cursor
    ) {
      if (
        (feedType === "following" || feedType === "followingMutual") &&
        this.refreshFeedMapsOnNextFetch
      ) {
        this.clearFollowingFeedMap();
        cursor = null;
        this.refreshFeedMapsOnNextFetch = false;
      }

      if (
        (feedType === "following" ||
          feedType === "all" ||
          feedType === "followingMutual") &&
        this.feedMaps[feedType].length > 5 &&
        !initialPosition
      ) {
        return {
          posts: this.feedMaps[feedType],
          next_cursor: cursor,
          hasMore: cursor ? true : false,
        };
      }

      const returnedFeedMap = await this.fetchFeedMapByType(
        feedType,
        feedParams,
        searchType,
        page,
        pageSize,
        cursor
      );

      let feedMap = returnedFeedMap.feedMap;
      let next_cursor = returnedFeedMap.next_cursor;

      // Fetch the posts
      if (feedMap && feedMap.length > 0) {
        if (feedType === "violations") {
          await this.fetchViolations(feedMap);
        }
      }

      // Update the hasMoreItems flag
      this.hasMoreItems = next_cursor !== null;

      return { posts: feedMap, next_cursor, hasMore: this.hasMoreItems };
    },

    async fetchXposts() {
      const xPostIds = await this.getxPostsFeedMap();

      this.fetchPostsByIds(xPostIds);
    },

    async fetchInitialFeed(
      feedType,
      feedParams,
      searchType,
      initialPosition,
      pageSize
    ) {
      return this.fetchFeedData(
        feedType,
        feedParams,
        searchType,
        initialPosition,
        pageSize
      );
    },

    async fetchMoreFeed(feedType, feedParams, searchType) {
      this.page += 1;
      return this.fetchFeedData(
        feedType,
        feedParams,
        searchType,
        this.page,
        this.cursor
      );
    },

    // Add this method to post store actions
    async cleanupOldPosts() {
      const MAX_CACHED_POSTS = 300; // Adjust based on your app's performance needs

      // Get the count of posts in the cache
      const postsCount = Object.keys(this.postsCache).length;

      // Only run cleanup if we have more than the maximum number of posts cached
      if (postsCount > MAX_CACHED_POSTS) {
        // Ensure visiblePosts is an array
        if (!Array.isArray(this.visiblePosts)) {
          this.visiblePosts = [];
        }

        // Create a set of visible posts to preserve
        const visiblePostIds = new Set(this.visiblePosts);

        // Create a set of posts that are in the current feed
        const currentFeedPosts = new Set();
        Object.values(this.feedMaps).forEach((feedMap) => {
          if (Array.isArray(feedMap)) {
            feedMap.forEach((id) => {
              if (id !== "END") {
                currentFeedPosts.add(id);
              }
            });
          }
        });

        // Get all post IDs and sort them by last viewed time (oldest first)
        const sortedPostIds = Object.keys(this.postsCache)
          // Don't clean up posts that are visible or in the current feed
          .filter((id) => !visiblePostIds.has(id) && !currentFeedPosts.has(id))
          .sort((a, b) => {
            const timeA = this.postLastViewedAt[a] || 0;
            const timeB = this.postLastViewedAt[b] || 0;
            return timeA - timeB;
          });

        // Calculate how many posts to remove
        // We'll remove enough to get back to 75% of the maximum
        const postsToRemoveCount = Math.max(
          0,
          postsCount - Math.floor(MAX_CACHED_POSTS * 0.75)
        );

        if (postsToRemoveCount > 0 && sortedPostIds.length > 0) {
          // Get the oldest posts up to the number we want to remove
          const idsToRemove = sortedPostIds.slice(
            0,
            Math.min(postsToRemoveCount, sortedPostIds.length)
          );

          // Remove the posts from the memory cache (but keep them in IndexedDB)
          idsToRemove.forEach((id) => {
            // Don't fully delete, just set to skeleton to save memory but keep the ID reserved
            // This way if we need the post again we'll restore from IndexedDB
            this.postsCache[id] = this.initializePostSkeleton(id);
          });
        }
      }
    },

    // New method to queue a post for engagement update
    queueForEngagementUpdate(postId) {
      if (!postId || !this.postsCache[postId]) return;

      // Add to the queue if not already present
      if (!this.postEngagementQueue.includes(postId)) {
        this.postEngagementQueue.push(postId);

        // Debounce the processing of the queue
        if (this._engagementUpdateTimer) {
          clearTimeout(this._engagementUpdateTimer);
        }

        // Set a short timer for processing if we have enough posts
        if (this.postEngagementQueue.length >= 5) {
          this._engagementUpdateTimer = setTimeout(() => {
            this._processEngagementQueue();
            this._engagementUpdateTimer = null;
          }, 1000); // 1 second debounce
        } else {
          // For smaller queues, use a longer idle timer to ensure eventual processing
          this._engagementUpdateTimer = setTimeout(() => {
            this._processEngagementQueue();
            this._engagementUpdateTimer = null;
          }, 3000); // 3 second idle timer for small queues
        }
      }

      // Set an idle timer to ensure posts get updated even if queue stays small
      if (!this._idleEngagementTimer) {
        this._idleEngagementTimer = setTimeout(() => {
          if (this.postEngagementQueue.length > 0) {
            this._processEngagementQueue();
          }
          this._idleEngagementTimer = null;
        }, 5000); // 5 seconds of idle time triggers engagement update regardless of queue size
      }
    },

    // Private method to process the engagement queue
    _processEngagementQueue() {
      if (this.postEngagementQueue.length === 0) return;

      // Clear any pending idle timer
      if (this._idleEngagementTimer) {
        clearTimeout(this._idleEngagementTimer);
        this._idleEngagementTimer = null;
      }

      // Ensure visiblePosts is an array
      if (!Array.isArray(this.visiblePosts)) {
        this.visiblePosts = [];
      }

      // Get posts to update that are still visible
      const postsToUpdate = this.postEngagementQueue.filter((id) =>
        this.visiblePosts.includes(id)
      );

      // Clear the queue
      this.postEngagementQueue = [];

      // Fetch engagements if we have visible posts to update
      if (postsToUpdate.length > 0) {
        this.fetchPostEngagements(postsToUpdate, true);
      }
    },

    // Update post visibility status
    updatePostVisibility(postId, isVisible) {
      if (!postId || !this.postsCache[postId]) return;

      // Always ensure visiblePosts is initialized as an array
      if (!Array.isArray(this.visiblePosts)) {
        this.visiblePosts = [];
      }

      if (isVisible) {
        // Add to visible posts if not already there
        if (!this.visiblePosts.includes(postId)) {
          this.visiblePosts.push(postId);
        }

        // Restore post from skeleton if needed
        if (this.postsCache[postId].isSkeleton) {
          this.restorePostFromSkeleton(postId);

          // Important: Don't queue for engagement update immediately after restoration
          // Let the visibility timer handle it after the post has been visible for a while

          // Clear any existing visibility timer for this post
          if (this._visibilityTimers?.[postId]) {
            clearTimeout(this._visibilityTimers[postId]);
          }

          // Set a new visibility timer that will queue for engagement update
          // only if the post remains visible for the specified duration
          this._visibilityTimers = this._visibilityTimers || {};
          this._visibilityTimers[postId] = setTimeout(() => {
            // Check if the post is still visible
            if (
              this.visiblePosts.includes(postId) &&
              !this.postsCache[postId].isSkeleton
            ) {
              this.queueForEngagementUpdate(postId);
            }
          }, 1000); // Only update engagements after 1 seconds of visibility
        } else {
          // For non-skeleton posts, use the normal queue method with debounce
          this.queueForEngagementUpdate(postId);
        }
      } else {
        // Remove from visible posts
        const index = this.visiblePosts.indexOf(postId);
        if (index > -1) {
          this.visiblePosts.splice(index, 1);
        }

        // Clear any visibility timer for this post
        if (this._visibilityTimers?.[postId]) {
          clearTimeout(this._visibilityTimers[postId]);
          delete this._visibilityTimers[postId];
        }

        // If no more visible posts, clear all engagement timers
        if (this.visiblePosts.length === 0) {
          if (this._engagementUpdateTimer) {
            clearTimeout(this._engagementUpdateTimer);
            this._engagementUpdateTimer = null;
          }

          if (this._idleEngagementTimer) {
            clearTimeout(this._idleEngagementTimer);
            this._idleEngagementTimer = null;
          }

          // Clear the queue since no posts are visible
          this.postEngagementQueue = [];
        }
      }
    },

    // Method to manually force an engagement update for a post
    forceEngagementUpdate(postId) {
      if (!postId || !this.postsCache[postId]) return;

      // Skip if post is a skeleton
      if (this.postsCache[postId].isSkeleton) {
        this.restorePostFromSkeleton(postId);
        return;
      }

      // Immediately fetch engagements for this post
      this.fetchPostEngagements([postId], false);

      // Update last engagement time
      this.postsCache[postId].lastEngagementUpdate = Date.now();
    },

    // Add a debounced method to queue a post for engagement update
    queuePostForEngagementUpdate(postId) {
      if (!postId || !this.postsCache[postId]) return;

      // Initialize the queue if needed
      if (!this.postEngagementQueue) {
        this.postEngagementQueue = [];
      }

      // Skip if already queued
      if (this.postEngagementQueue.includes(postId)) {
        return;
      }

      // Add a visibility timer for this post
      const visibilityTimer = setTimeout(() => {
        // Check if the post is still visible (handle both array and object variants)
        const isStillVisible = Array.isArray(this.visiblePosts)
          ? this.visiblePosts.includes(postId)
          : Boolean(this.visiblePosts[postId]);

        if (isStillVisible) {
          // Add to the engagement queue
          this.postEngagementQueue.push(postId);

          // Process the queue with debounce
          if (!this._engagementUpdateTimer) {
            this._engagementUpdateTimer = setTimeout(() => {
              this.processEngagementQueue();
              this._engagementUpdateTimer = null;
            }, 1000); // 1 second debounce
          }
        }
      }, 2500); // Only queue if the post is visible for at least 2.5 seconds

      // Store the timer so we can clear it if needed
      this._visibilityTimers = this._visibilityTimers || {};
      this._visibilityTimers[postId] = visibilityTimer;
    },

    // Process the engagement update queue
    processEngagementQueue() {
      if (!this.postEngagementQueue || this.postEngagementQueue.length === 0) {
        return;
      }

      // Ensure visiblePosts is an array
      if (!Array.isArray(this.visiblePosts)) {
        this.visiblePosts = [];
      }

      // Get only the posts that are still visible
      const visiblePostsToUpdate = this.postEngagementQueue.filter((postId) =>
        this.visiblePosts.includes(postId)
      );

      // Clear the queue
      this.postEngagementQueue = [];

      // Fetch engagements in batch if we have visible posts
      if (visiblePostsToUpdate.length > 0) {
        this.fetchPostEngagements(visiblePostsToUpdate, true);
      }
    },
  },
});
