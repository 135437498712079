<template>
  <div
    v-if="hasOgData"
    class="og-display-container cursor-pointer select-none"
    :class="{ 'in-carousel': inCarousel }"
    @click="openLink"
  >
    <!-- Image preview for OG content -->
    <div v-if="post.openGraph.image && !hideImage" class="relative">
      <img
        :src="post.openGraph.image"
        :class="[
          post.openGraph.title ? 'rounded-t-lg' : 'rounded-lg',
          'w-full object-cover',
          inCarousel ? 'carousel-image' : 'max-h-[50vh]',
        ]"
        alt="Link preview image"
        @error="handleImageError"
      />
    </div>
    <!-- OG metadata section -->
    <div
      v-if="post.openGraph.title || post.openGraph.description"
      class="p-2 bg-gray-200 dark:bg-zinc-950 space-y-1"
      :class="post.openGraph.image && !imageError ? 'rounded-b-lg' : 'rounded-lg'"
    >
      <span v-if="post.openGraph.title" class="px-2 font-bold line-clamp-1 text-gray-500">
        {{ post.openGraph.title }}
      </span>
      <span
        v-if="post.openGraph.description"
        class="line-clamp-2 overflow-hidden px-2 text-[0.8rem]"
      >
        {{ post.openGraph.description }}
      </span>
      <span class="line-clamp-1 px-2 text-[0.75rem] tag-link underline">
        {{ post.openGraph.url }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";

const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
  hideImage: {
    type: Boolean,
    default: false,
  },
  inCarousel: {
    type: Boolean,
    default: false,
  },
});

const imageError = ref(false);

// Determine if we have valid OG data to display
const hasOgData = computed(() => {
  return (
    props.post?.openGraph &&
    (props.post.openGraph.title ||
      props.post.openGraph.description ||
      props.post.openGraph.image ||
      props.post.openGraph.url) &&
    !props.post?.embedUrl
  );
});

// Handle image load errors
function handleImageError() {
  imageError.value = true;
}

// Open the link in a new tab
function openLink() {
  if (props.post?.openGraph?.url) {
    window.open(props.post.openGraph.url, "_blank", "noopener,noreferrer");
  }
}
</script>

<style scoped>
.tag-link {
  color: var(--tertiary-color);
}

.og-display-container {
  width: 100%;
  overflow: hidden;
  margin-bottom: 0.5rem;
  padding: 0 0.5em;
}

.in-carousel {
  margin-bottom: 0;
}

.carousel-image {
  max-height: 35vh;
}
</style>
