<template>
  <!-- EAUbFkUUTzQMBhFJFllhMx4GH0VWEHFdMQ8aGhFEaS1cT1UpVBJxQkZJL0xdQ1dQYy8GAQYRSTdJOw0WRVRhLRxSOwVHWkRBFBEJHBYXEkUn-1707057685. -->

  <div id="modal-container"></div>
  <!--  <ion-split-pane :class="{'container mx-auto': !isMobile}" content-id="main-content">-->
  <!--    <ion-menu  content-id="main-content" type="overlay" class="bg-white w-64">-->
  <!--      <ion-content v-if="authStore.currentUser">-->
  <!--        <div class="flex items-center justify-between px-4 py-2">-->
  <!--          <ion-icon slot="start" class="text-2xl"></ion-icon>-->
  <!--        </div>-->
  <!--        <ion-list class="mt-4" lines="none">-->
  <!--          <ion-item v-for="item in menuItems" :key="item.label" class="text-lg font-bold select-none cursor-pointer" @click="item.action">-->
  <!--            <ion-icon slot="start" :icon="item.icon"></ion-icon>-->
  <!--            <ion-label>{{ item.label }}</ion-label>-->
  <!--          </ion-item>-->
  <!--        </ion-list>-->
  <!--        <ion-card class="my-4">-->
  <!--          <ion-card-content>-->
  <!--            <ion-card-title class="text-lg font-bold">-->
  <!--              Dark Mode-->
  <!--              <ion-toggle @ionChange="toggleDarkMode" :checked="darkMode" class="float-right"/>-->
  <!--            </ion-card-title>-->
  <!--            <p>Toggle dark mode for the app</p>-->
  <!--          </ion-card-content>-->
  <!--        </ion-card>-->

  <!--        <LogOutCard />-->

  <!--      </ion-content>-->
  <!--    </ion-menu>-->
  <!--  </ion-split-pane>-->
  <ion-toast
    v-if="isBackendDown"
    :is-open="isBackendDown"
    class="alert"
    position="top"
    message="Server unavailable. We're fixing it; please try again soon."
    color="danger"
    :icon="alertIcon"
    :buttons="[
      {
        text: 'Close',
        role: 'cancel',
      },
    ]"
  >
  </ion-toast>
  <ion-toast
    v-if="backendRecovered"
    :is-open="backendRecovered"
    class="alert"
    message="Server is back online. Thank you for waiting!"
    duration="5000"
    position="top"
    color="success"
    :icon="checkMarkIcon"
    :buttons="[
      {
        text: 'Close',
        role: 'cancel',
      },
    ]"
  >
  </ion-toast>
  <ion-toast
    v-if="wrongURL && authStore.authenticated"
    :is-open="wrongURL"
    class="alert"
    message="Invalid URL"
    duration="2000"
    position="top"
    :icon="alertCircle"
    @didDismiss="clearWrongURLCookie"
  />
  <TimeoutAlert />

  <!-- <ion-toast v-if="imageProcessed" :is-open="imageProcessed" class="alert"
  message="Image processing finished" duration="5000" position="top" color="success" @click="NavigateToPost" :icon="images" /> -->

  <ion-router-outlet
    id="main-content"
    class="md:mx-auto md:w-11/12"
  ></ion-router-outlet>
</template>

<script setup>
import { IonRouterOutlet, onIonViewDidEnter, IonToast } from "@ionic/vue";
import { alertCircle, search } from "ionicons/icons";
import "./assets/main.scss";
import { onMounted, onBeforeUnmount, watch, computed, ref } from "vue";
import { apiBackendAxios } from "@/axiosAuth";
import { apiBackendAuthAxios } from "@/axiosAuth";
import { useAuthStore } from "@/stores/auth";
import { useGlobalStore } from "@/stores/global";
import { usePostStore } from "@/stores/post";
import { useUserStore } from "@/stores/users";
import { useAdminStore } from "@/stores/admin";
import { useNotificationStore } from "@/stores/notification";
import TimeoutAlert from "./components/moderation/TimeoutAlert.vue";
import { useRoute, useRouter } from "vue-router";
import Echo from "laravel-echo";
import Pusher from "pusher-js";

/** @type {Ref<boolean>} */
const authStore = useAuthStore();
const globalStore = useGlobalStore();
const postStore = usePostStore();
const userStore = useUserStore();
const notificationStore = useNotificationStore();
const adminStore = useAdminStore();
const isAuth = computed(() => authStore.authenticated);
const alertIcon = alertCircle;
const recoveryTimeout = ref(null);
const imageProcessed = ref(false);
const processedPostId = ref(null);
const isUpdating = ref(false);
const route = useRoute();
const isBackendDown = computed(() => globalStore.isBackendDown);
const backendRecovered = ref(false);
const wrongURL = computed(() => {
  return getCookie("wrongURL") === "true";
});

watch(isBackendDown, (newVal) => {
  if (newVal === false) {
    backendRecovered.value = true;
  }
});

onIonViewDidEnter(() => {
  if (globalStore.processingPosts.length > 0) {
    postStore.isProcessing();
  }
});

watch(isAuth, (newVal) => {
  if (newVal === true) {
    startEcho();
  }
  if (newVal === false) {
    window.Echo.leave(`notifications.${authStore.currentUser.ulid}`);
    window.Echo.leave(`posts`);
  }
});

async function startEcho() {
  await checkApi();

  window.Pusher = Pusher;

  window.Echo = new Echo({
    broadcaster: "pusher",
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,

    wsHost: import.meta.env.VITE_ECHO_HOST,
    wsPort: import.meta.env.VITE_ECHO_PORT,
    wssPort: import.meta.env.VITE_ECHO_PORT,
    forceTLS: true,
    encrypted: true,
    enableStats: false,
    enabledTransports: ["ws", "wss"],
    authEndpoint: import.meta.env.VITE_API_HOST + "/broadcasting/auth",
    auth: {
      headers: {
        Authorization: `Bearer ${authStore.accessToken}`,
        Accept: "application/json",
      },
    },
  });

  window.Echo.channel("posts").listen("Posts\\PostAdded", (e) => {
    processAddPost(e);
  });

  if (authStore.currentUser && authStore.currentUser.ulid) {
    setupPrivateChannelSubscriptions(authStore.currentUser.ulid);
  } else {
    console.error("User ulid is undefined, cannot set up private channels");
  }
}

function setupPrivateChannelSubscriptions(ulid) {
  // Listen to NotificationCount event
  window.Echo.private(`notifications.${ulid}`).listen(
    "NotificationCount",
    (e) => {
      if (e.unseenCount > 0) {
        notificationStore.totalUnseen = e.unseenCount;
      } else if (e.unseenNotifications) {
        notificationStore.notifications.unseen = e.unseenNotifications;

        // Update the total unseen notifications count based on the length of the array
        const totalUnseen = e.unseenNotifications.length;
        notificationStore.totalUnseen = totalUnseen;
      }
    }
  );

  window.Echo.private(`notifications.${ulid}`).listen(
    "Posts\\PostImageFinished",
    (e) => {
      const response = postStore.fetchPostsByIds([e.ulid], true);
    }
  );

  window.Echo.private(`user-${ulid}`).listen("Posts\\Engagement", (e) => {
    const engagements = e.data;

    if (globalStore.processingPosts.length > 0) {
      postStore.isProcessing();
    }

    // Update the engagements in the database and cache
    postStore.updatePostEngagementsInDB(engagements, false);
  });
}

async function checkApi() {
  const { validateAccessToken, refreshAccessToken } = await import(
    "@/axiosAuth"
  );

  try {
    const isValid = await validateAccessToken(authStore.accessToken);
    if (!isValid) {
      await refreshAccessToken();
    }
  } catch (error) {
    console.error("API check failed:", error);
  }
}

let debounceTimer = null;

function processAddPost(e) {
  const engagement = userStore.getProfileEngagement(e.userUlid);

  const isFollowing = engagement && engagement.isFollowing;
  const isMutual =
    engagement && engagement.isFollowing && engagement.isFollowYou;

  if (globalStore.currentFeed === "followingMutual" && isMutual) {
    postStore.checkForNewPostsPusher();
  } else if (globalStore.currentFeed === "following" && isFollowing) {
    postStore.checkForNewPostsPusher();
  } else if (globalStore.currentFeed === "all") {
    // Trigger once every 1 minute
    if (!debounceTimer) {
      postStore.checkForNewPostsPusher();
      debounceTimer = setTimeout(() => {
        debounceTimer = null;
      }, 60000); // 1 minute
    }
  } else if (route.name === "Group") {
    const groupId = route.params.id;

    postStore.fetchNewGroupPosts(groupId);
  }
}

async function checkForUpdate() {
  if (authStore.authenticated) {
    const currentVersion = import.meta.env.VITE_APP_VERSION;

    if (currentVersion !== globalStore.version) {
      await globalStore.refreshTypes();
      await adminStore.fetchViolationTypes();
      globalStore.version = currentVersion;

      // Send message to service worker to clear cache
      if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({
          action: "clearCache",
        });
      }
    }
  }
}

async function handleVisibilityChange() {
  if (
    document.visibilityState === "visible" &&
    !isUpdating.value &&
    route.name === "home" &&
    postStore.feedMaps[globalStore.currentFeed].length > 0
  ) {
    // Call the function when the app becomes visible

    postStore.checkForNewPostsPusher();
  }
}

const swTimer = ref(null);

onMounted(async () => {
  checkForUpdate();

  updateMetaTag();

  // Add an event listener for the custom event 'push-notification'
  window.addEventListener("push-notification", function (event) {
    // Get the detail from the event, which contains the notification payload
    const notificationData = event.detail;

    // Parse the JSON string into an object if necessary
    let data;
    try {
      data =
        typeof notificationData === "string"
          ? JSON.parse(notificationData)
          : notificationData;
    } catch (e) {
      console.error("Failed to parse notification data:", e);
      return;
    }

    if (data.isClicked) {
      // Handle the notification click
      if (data.url) {
        window.location.href = data.url; // Navigate to the URL
      }
    }
  });

  window.addEventListener("error", errorHandler, true);

  document.addEventListener("visibilitychange", handleAppFocus);
  window.addEventListener("focus", handleAppFocus);

  globalStore.setDarkMode();

  handleOTP();

  if (authStore.authenticated) {
    startEcho();

    globalStore.setupClearDataInterval(); // Set up the interval
  }

  if (globalStore.settings.primary_colors !== null) {
    document.documentElement.style.setProperty(
      "--primary-color",
      globalStore.settings.primary_colors
    );
  }

  if (globalStore.settings.secondary_colors !== null) {
    document.documentElement.style.setProperty(
      "--secondary-color",
      globalStore.settings.secondary_colors
    );
  }

  if (globalStore.settings.tertiary_colors !== null) {
    document.documentElement.style.setProperty(
      "--tertiary-color",
      globalStore.settings.tertiary_colors
    );
  }

  // Add validation on mount
  if (authStore.authenticated && authStore.accessToken) {
    console.log("App mounted - validating auth token state");
    try {
      await authStore.validateTokenState();
    } catch (error) {
      console.error("Failed to validate token on app mount:", error);
      // Don't force logout here - let the auth interceptors handle that
    }
  }

  // Listen for service worker messages about new versions
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.addEventListener("message", async (event) => {
      if (
        event.data &&
        event.data.action === "newVersionAvailable" &&
        event.data.preserveAuth
      ) {
        console.log("New app version with preserveAuth flag detected");

        // If we're authenticated, validate our token
        if (authStore.authenticated && authStore.accessToken) {
          try {
            await authStore.validateTokenState();
          } catch (error) {
            console.error(
              "Failed to validate token after version update:",
              error
            );
          }
        }
      }
    });
  }
});

watch(
  () => route.path,
  () => {
    updateMetaTag();
  }
);

// Function to update the iOS smart app banner meta tag
function updateMetaTag() {
  const currentUrl = window.location.href; // The current URL is used as the new app-argument
  const metaTag = document.querySelector('meta[name="apple-itunes-app"]');
  if (metaTag) {
    let content = metaTag.getAttribute("content");
    // Regular expression to find existing 'app-argument' if present
    const appArgumentRegex = /, app-argument=[^,]+/;
    // Check if 'app-argument' is already part of the content
    if (appArgumentRegex.test(content)) {
      // Replace the existing 'app-argument'
      content = content.replace(
        appArgumentRegex,
        `, app-argument=${currentUrl}`
      );
    } else {
      // Append 'app-argument' if not present
      content += `, app-argument=${currentUrl}`;
    }
    // Set the modified content back to the meta tag
    metaTag.setAttribute("content", content);
  }
}
const errorHandler = (event) => {
  // Check if the error is related to a script load failure
  if (event.target && event.target.tagName === "SCRIPT") {
    console.error("Script load error:", event.target.src);

    // Attempt to fetch the script to confirm it's a 403 error
    fetch(event.target.src)
      .then((response) => {
        if (response.status === 403) {
          console.error(
            "Component not available on CDN - 403 Forbidden",
            response.status
          );
          // Trigger the service worker update or refresh the page
          window.location.reload(true);
        }
      })
      .catch(console.error);
  }
};

// Set up a watcher on the currentFeed
watch(
  () => globalStore.currentFeed,
  (newFeed, oldFeed) => {
    // Trigger or close event listeners based on currentFeed
    if (
      (newFeed === "followingMutual" ||
        newFeed === "following" ||
        newFeed === "all" ||
        newFeed === "influencers") &&
      (oldFeed === "profile" || oldFeed === "search")
    ) {
      document.addEventListener("visibilitychange", handleVisibilityChange);
      window.addEventListener("focus", handleVisibilityChange);
    } else if (
      (oldFeed === "followingMutual" ||
        oldFeed === "following" ||
        oldFeed === "all" ||
        newFeed === "influencers") &&
      (newFeed === "profile" || newFeed === "search")
    ) {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("focus", handleVisibilityChange);
    }
  }
);

function handleAppFocus() {
  if (
    (document.visibilityState === "visible" || document.hasFocus()) &&
    !isUpdating.value &&
    postStore.feedMaps[globalStore.currentFeed]?.length > 0 &&
    route.name === "home"
  ) {
    isUpdating.value = true;

    //   try {
    //     postStore
    //       .checkForNewPostsPusher()
    //       .then(() => {
    //         isUpdating.value = false;
    //       })
    //       .catch((error) => {
    //         console.error("Failed to update posts:", error);
    //         isUpdating.value = false;
    //       });
    //   } catch (error) {
    //     console.error("Error handling app focus:", error);
    //     isUpdating.value = false;
    //   }
  }
}

// const { isMobile } = useDeviceDetection();

// const menuItems = [
//   { label: "Home", icon: homeOutline, action: () => router.replace("/") },
//   { label: "Search", icon: searchOutline, action: () => router.push("/search") },
//   {
//     label: "Notifications",
//     icon: notificationsOutline,
//     action: () => router.push("/notifications"),
//   },
//   { label: "Messages", icon: mailOutline, action: () => router.push("/") },
//   {
//     label: "Profile",
//     icon: personCircleOutline,
//     action: () => router.push(`/${authStore.currentUser.username}`),
//   },
// ];

/**
 * This function is responsible for handling OTP entries.
 * It checks if the URL has a parameter for 'otp'. If it does,
 * it retrieves the otp and sends a post request to `/magicLogin` API endpoint,
 * with the otp as a body parameter.
 * If the response from the server contains a 'token'
 * it sets the user as authenticated and routes them to the application's home page.
 *
 * @async
 * @function handleOTP
 * @example
 * // usage
 * handleOTP();
 * @returns {Promise<void>} Nothing
 * @throws {Error} If there's an error within the async operation, it will bubble up to the invoker.
 */

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(";").shift();
  }
  return null;
}

async function handleOTP() {
  const router = useRouter();
  const rawUrl = window.location.href;
  let otp = null;
  let redirect = "/feed";

  // Check for magic link format with path extraction (existing logic)
  if (window.location.pathname.includes("/magicLogin/")) {
    const pathParts = window.location.pathname.split("/");
    const magicLoginIndex = pathParts.indexOf("magicLogin");

    if (magicLoginIndex >= 0 && magicLoginIndex < pathParts.length - 1) {
      const token = pathParts[magicLoginIndex + 1];

      // Get all URL parameters
      const urlParams = new URLSearchParams(window.location.search);

      // Extract redirect param if present
      if (urlParams.has("redirect")) {
        redirect = urlParams.get("redirect");
      }

      try {
        const response = await apiBackendAxios.post("/magicLogin", {
          token: token,
        });

        if (!response.data) {
          router.replace("/login");
          return;
        }

        const tokenData = response.data;

        // Try to authenticate
        const authResult = await authStore.setAuthenticated(tokenData);

        if (authResult) {
          await authStore.setUser();

          if (!authStore.currentUser) {
            router.replace("/login");
            return;
          }

          if (!authStore.currentUser.accountSetup) {
            globalStore.redirect = redirect;
            router.replace("/setup");
          } else if (!authStore.currentUser.acceptTermsOfService) {
            globalStore.redirect = redirect;
            router.replace("/policy");
          } else {
            router.replace(redirect);
          }
        } else {
          router.replace("/login");
        }
        return;
      } catch (error) {
        router.replace("/login");
      }
    }
  }

  // Check for direct OTP parameter
  const url = new URL(window.location.href);
  if (url.searchParams.has("otp")) {
    otp = url.searchParams.get("otp");
    redirect = url.pathname;
  } else {
    // Check for OTP in redirect parameter with malformed URL
    if (url.searchParams.has("redirect")) {
      const redirectValue = url.searchParams.get("redirect");

      // Check if the redirect contains a malformed query string using ? instead of &
      if (redirectValue.includes("?otp=")) {
        const otpMatch = redirectValue.match(/\?otp=([^&]+)/);
        if (otpMatch && otpMatch[1]) {
          otp = otpMatch[1];

          // Extract the actual redirect path without the OTP parameter
          redirect = redirectValue.split("?otp=")[0];
        }
      }
    }
  }

  // Proceed with magic login if OTP was found
  if (otp) {
    try {
      const response = await apiBackendAxios.post("/magicLogin", { otp: otp });

      if (!response.data) {
        router.replace("/login");
        return;
      }

      const tokenData = response.data;

      if (tokenData && (tokenData.access_token || tokenData.token)) {
        await authStore.setAuthenticated(tokenData);
        await authStore.setUser();

        if (!authStore.currentUser.accountSetup) {
          globalStore.redirect = redirect;
          router.replace("/setup");
        } else if (!authStore.currentUser.acceptTermsOfService) {
          globalStore.redirect = redirect;
          router.replace("/policy");
        } else if (redirect !== "/") {
          router.replace(redirect);
        } else {
          router.replace("/feed");
        }
      } else {
        router.replace("/login");
      }
    } catch (error) {
      router.replace("/login");
    }
  }
}

function clearWrongURLCookie() {
  document.cookie = "wrongURL=false;max-age=0;path=/";
}

onBeforeUnmount(() => {
  if (hideTimeout.value) {
    clearTimeout(hideTimeout.value);
  }

  if (globalStore.clearDataInterval) {
    clearInterval(globalStore.clearDataInterval);
  }
  if (
    globalStore.currentFeed === "followingMutual" ||
    globalStore.currentFeed === "following" ||
    globalStore.currentFeed === "all"
  ) {
    document.removeEventListener("visibilitychange", handleVisibilityChange);
    window.removeEventListener("focus", handleVisibilityChange);
  }
  if (window.Echo) {
    window.Echo.private(
      `notifications.${authStore.currentUser.ulid}`
    ).stopListening("NotificationCount");
    window.Echo.private(
      `notifications.${authStore.currentUser.ulid}`
    ).stopListening("Posts\\PostImageFinished");
    window.Echo.channel("posts").stopListening("Posts\\PostAdded");
    window.Echo.leave(`notifications.${authStore.currentUser.ulid}`);
    window.Echo.leave("posts");
  }
  if (globalStore.clearDataInterval) {
    clearInterval(globalStore.clearDataInterval);
  }
  if (recoveryTimeout.value) {
    clearInterval(recoveryTimeout.value);
  }

  if (swTimer.value) {
    clearInterval(swTimer.value);
  }

  if (debounceTimer) {
    clearTimeout(debounceTimer);
    debounceTimer = null;
  }

  window.removeEventListener("error", errorHandler, true);

  document.removeEventListener("visibilitychange", handleAppFocus);
  window.removeEventListener("focus", handleAppFocus);

  window.removeEventListener("push-notification", function (event) {});
});
</script>
<style scoped>
.alert {
  top: 56px !important;
}
</style>
<style>
/* mixins already imported globally in main.scss */
</style>
